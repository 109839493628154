import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const LL = {
  ACTUAL_HA: 'Diện tích thực hien',
  ACTUAL_BUNCHES: 'Số lượng buồng thực tế',
  MAN_HOUR: 'Man Hour',
  NUM_OF_MAN: 'Số luong công nhân',
  SELECT: 'Lựa chọn',
  RIBBON_COLOR: 'Màu dây',
  ACTIVITY_DETAILS: 'Chi tiết cv',
  MATERIALS: 'Vật tư',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Step1({ insertData, ribbonColorList, setInsertData, materialList }) {
  const classes = useStyles();

  const handleLotChange = e => {
    const labelArr = e.target.name.split('.');
    const tabIndex = parseInt(labelArr[0]);
    const lotIndex = parseInt(labelArr[1]);
    const field = labelArr[2];

    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        lot: [
          ...insertData[tabIndex].lot.slice(0, lotIndex),
          {
            ...insertData[tabIndex].lot[lotIndex],
            [field]: e.target.value,
          },
          ...insertData[tabIndex].lot.slice(lotIndex+1),
        ],
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  const handleTabChange = e => {
    const labelArr = e.target.name.split('.');
    const tabIndex = parseInt(labelArr[0]);
    const field = labelArr[1];

    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        [field]: e.target.value,
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  const handleRemoveLot = (tabIndex, lotIndex) => {
    if (insertData[tabIndex].lot.length === 1) {
      return;
    }

    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        lot: [
          ...insertData[tabIndex].lot.slice(0, lotIndex),
          ...insertData[tabIndex].lot.slice(lotIndex+1),
        ],
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  const handleAddMaterial = tabIndex => {
    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        materials: [
          ...insertData[tabIndex].materials,
          {
            'material': '',
            'value': '',
            'metric': '',
          },
        ],
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  const handleMaterialChange = e => {
    const labelArr = e.target.name.split('.');
    const tabIndex = parseInt(labelArr[0]);
    const materialIndex = parseInt(labelArr[1]);
    const field = labelArr[2];
    const value = e.target.value;

    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        materials: [
          ...insertData[tabIndex].materials.slice(0, materialIndex),
          {
            ...insertData[tabIndex].materials[materialIndex],
            [field]: value,
            metric: field === 'material' ? materialList.find(m => m.material === value).metric : insertData[tabIndex].materials[materialIndex].metric,
          },
          ...insertData[tabIndex].materials.slice(materialIndex+1),
        ],
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  const handleRemoveMaterial = (tabIndex, materialIndex) => {
    setInsertData([
      ...insertData.slice(0,tabIndex),
      {
        ...insertData[tabIndex],
        materials: [
          ...insertData[tabIndex].materials.slice(0, materialIndex),
          ...insertData[tabIndex].materials.slice(materialIndex+1),
        ],
      },
      ...insertData.slice(tabIndex+1),
    ]);
  };

  return (
    <React.Fragment>
      {
        insertData.map((item, index) => (
          <React.Fragment key={index}>
            <Typography variant="h6" gutterBottom>
              {item.name}
            </Typography>
            <Grid container spacing={3}>
              {
                item.lot.map((lotItem, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name={`${index}.${subIndex}.plan`}
                        label={`${lotItem.name} Plan(ha)`}
                        fullWidth
                        value="N/A"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`${index}.${subIndex}.actualHa`}
                        label={`${lotItem.name} ${LL.ACTUAL_HA}`}
                        value={lotItem.actualHa}
                        fullWidth
                        onChange={handleLotChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`${index}.${subIndex}.actualBunches`}
                        label={`${lotItem.name} ${LL.ACTUAL_BUNCHES}`}
                        value={lotItem.actualBunches}
                        fullWidth
                        onChange={handleLotChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton
                        color="primary"
                        onClick={() => handleRemoveLot(index, subIndex)}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))
              }
              <br/>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`${index}.manHour`}
                  label={LL.MAN_HOUR}
                  value={item.manHour}
                  fullWidth
                  onChange={handleTabChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`${index}.numOfMan`}
                  label={LL.NUM_OF_MAN}
                  value={item.numOfMan}
                  fullWidth
                  onChange={handleTabChange}
                />
              </Grid>
              {
                item.id === 0 ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{`${LL.RIBBON_COLOR} 1`}</InputLabel>
                        <Select
                          name={`${index}.ribbonColor`}
                          value={item.ribbonColor}
                          onChange={handleTabChange}
                        >
                          <MenuItem value={''}>{LL.SELECT}</MenuItem>
                          {
                            ribbonColorList.map(ribbonColor => (
                              <MenuItem key={ribbonColor.id} value={ribbonColor.color}>{ribbonColor.color}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{`${LL.RIBBON_COLOR} 2`}</InputLabel>
                        <Select
                          name={`${index}.ribbonColorSub`}
                          value={item.ribbonColorSub}
                          onChange={handleTabChange}
                        >
                          <MenuItem value={''}>{LL.SELECT}</MenuItem>
                          {
                            ribbonColorList.map(ribbonColor => (
                              <MenuItem key={ribbonColor.id} value={ribbonColor.color}>{ribbonColor.color}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} />
                  </>
                ) : item.id === 1 ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`${index}.otherName`}
                        label={LL.ACTIVITY_DETAILS}
                        value={item.otherName}
                        fullWidth
                        onChange={handleTabChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} />
                  </>
                ) : null
              }
              <br/>

              {
                item.id === 0 || item.id === 5 ? (
                  <>
                    <Typography variant="subtitle1" gutterBottom>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{LL.MATERIALS}
                      <IconButton color="primary" onClick={() => handleAddMaterial(index)}>
                        <AddBoxIcon />
                      </IconButton>
                    </Typography>
                    {
                      item.materials.map((material, subIndex) => (
                        <Grid container spacing={3} key={subIndex}>
                          <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel>{LL.MATERIALS}</InputLabel>
                              <Select
                                name={`${index}.${subIndex}.material`}
                                fullWidth
                                value={material.material}
                                onChange={handleMaterialChange}
                              >
                                <MenuItem value={''}>{LL.SELECT}</MenuItem>
                                {
                                  materialList.map(materialItem => (
                                    <MenuItem
                                      key={materialItem.id}
                                      value={materialItem.material}
                                    >
                                      {materialItem.material}
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name={`${index}.${subIndex}.value`}
                              label="value"
                              fullWidth
                              value={material.value}
                              onChange={handleMaterialChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              label="metric"
                              fullWidth
                              disabled
                              value={material.metric}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <IconButton color="primary" onClick={() => handleRemoveMaterial(index, subIndex)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    }
                  </>
                ) : null
              }
            </Grid>

            <br/><br/>
          </React.Fragment>
        ))
      }
    </React.Fragment>
  );
}
