import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import BarChartIcon from '@material-ui/icons/BarChart';
import HistoryIcon from '@material-ui/icons/History';

export function AdminMenu(props) {
  return (
    <div>
      <ListSubheader inset>Admin Menu</ListSubheader>
      <ListItem
        button
        selected={props.selectedMenu === 'USER_MANAGEMENT'}
        onClick={() => props.goToUserManagement()}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="User Manage" />
      </ListItem>
      <ListItem
        button
        selected={props.selectedMenu === 'DATA_MANAGEMENT1'}
        onClick={() => props.goToDataManagement1()}
      >
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Data Manage 1" />
      </ListItem>
      <ListItem
        button
        selected={props.selectedMenu === 'DATA_MANAGEMENT2'}
        onClick={() => props.goToDataManagement2()}
      >
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Data Manage 2" />
      </ListItem>
    </div>
  );
}

export function OfficerMenu(props) {
  return (
    <div>
      <ListSubheader inset>Officer Page</ListSubheader>
      <ListItem
        button
        selected={props.selectedMenu === 'DATA_VIEWER'}
        onClick={() => props.goToDataViewer()}
      >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Data Export" />
      </ListItem>
      <ListItem
        button
        selected={props.selectedMenu === 'DATA_CONTROL'}
        onClick={() => props.goToDataControl()}
      >
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Data Control" />
      </ListItem>
    </div>
  );
}

export function FarmerMenu(props) {
  return (
    <div>
      <ListSubheader inset>Farmer Page</ListSubheader>
      <ListItem
        button
        selected={props.selectedMenu === 'DAILY_SUBMISSION'}
        onClick={() => props.goToDailySubmission()}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Daily Submission" />
      </ListItem>
      <ListItem
        button
        selected={props.selectedMenu === 'SUBMISSION_HISTORY'}
        onClick={() => props.goToSubmissionHistory()}
      >
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText primary="Submission History" />
      </ListItem>
    </div>
  );
}

export function CommonMenu(props) {
  return (
    <div>
      <ListSubheader inset>personal settings</ListSubheader>
      <ListItem
        button
        selected={props.selectedMenu === 'PASSWORD_RESET'}
        onClick={() => props.goToPasswordReset()}
      >
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText primary="password reset" />
      </ListItem>
    </div>
  );
}
