import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import DataManagement1 from "./pages/DataManagement1";
import DataManagement2 from "./pages/DataManagement2";
import DataViewer from "./pages/DataViewer";
import DailySubmission from "./pages/DailySubmission";
import SubmissionHistory from "./pages/SubmissionHistory";
import PasswordReset from "./pages/PasswordReset";
import DataControl from "./pages/DataControl";
import DailySubmissionControl from "./pages/DailySubmissionControl";
import SubmissionHistoryControl from "./pages/SubmissionHistoryControl";

import { useSelector } from 'react-redux';

const theme = createTheme({
    // typography:{
    //     fontFamily: 'TmoneyRoundWindExtraBold',
    //     fontSize: 11,
    // },
  }
);

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    height: "100vh",
    overflowY: 'auto',
    overflowX: 'auto',
  },
}));

function App() {
  const classes = useStyles();
  const { data } = useSelector(state => state.auth.authInfo);
  const isAuth = data && data.accessToken;

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <Route path="/signin" exact>
            <SignIn />
          </Route>
          <Route path="/dashboard">
            {
              isAuth ? (
                <Dashboard
                  subComp={(
                    <Switch>
                      {
                        data.userRole === "A" ? (
                          <Redirect exact from="/dashboard" to="/dashboard/user-management" />
                        ) : data.userRole === "B1" || data.userRole === "B2" || data.userRole === "B3" ? (
                          <Redirect exact from="/dashboard" to="/dashboard/data-viewer" />
                        ) : data.userRole === "C" || data.userRole === "D" ? (
                          <Redirect exact from="/dashboard" to="/dashboard/submission-history" />
                        ) : (
                          <Redirect exact from="/dashboard" to="/dashboard/password-reset" />
                        )
                      }
                      <Route path="/dashboard/user-management" exact>
                        <UserManagement />
                      </Route>
                      <Route path="/dashboard/data-management1" exact>
                        <DataManagement1 />
                      </Route>
                      <Route path="/dashboard/data-management2" exact>
                        <DataManagement2 />
                      </Route>
                      <Route path="/dashboard/data-viewer" exact>
                        <DataViewer />
                      </Route>
                      <Route path="/dashboard/daily-submission" exact>
                        <DailySubmission />
                      </Route>
                      <Route path="/dashboard/submission-history" exact>
                        <SubmissionHistory />
                      </Route>
                      <Route path="/dashboard/data-control" exact>
                        <DataControl />
                      </Route>
                      <Route path="/dashboard/data-control/daily-submission" exact>
                        <DailySubmissionControl />
                      </Route>
                      <Route path="/dashboard/data-control/submission-history" exact>
                        <SubmissionHistoryControl />
                      </Route>
                      <Route path="/dashboard/password-reset" exact>
                        <PasswordReset />
                      </Route>
                    </Switch>
                  )}
                />
              ) : (
                <Redirect to="/signin" />
              )
            }
          </Route>
        </Switch>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
