import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import auth, { authSaga } from './auth';
import tab, { tabSaga } from './tab';
import user, { userSaga } from './user';
import harvest, { harvestSaga } from './harvest';
import packing, { packingSaga } from './packing';
import control, { controlSaga } from './control';

const rootReducer = combineReducers({
    auth,
    tab,
    user,
    harvest,
    packing,
    control,
});

export function* rootSaga() {
    yield all([
        authSaga(),
        tabSaga(),
        userSaga(),
        harvestSaga(),
        packingSaga(),
        controlSaga(),
    ]); // all 은 배열 안의 여러 사가를 동시에 실행시켜줍니다.
}

export default rootReducer;
