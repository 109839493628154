import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SubmissionHistoryControlContainer from '../containers/SubmissionHistoryControl'

const useQuery = () => new URLSearchParams(useLocation().search);

export default function SubmissionHistoryControlPage() {
  const history = useHistory();
  const query = useQuery();

  return (
    <SubmissionHistoryControlContainer
      userId={query.get("userId")}
      goToDashboardHome={() => history.replace("/dashboard")}
      goToSubmissionPage={() => history.push(`/dashboard/data-control/daily-submission?userId=${query.get("userId")}`)}
    />
  );
}
