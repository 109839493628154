import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserManagementComponent from '../components/UserManagement';
import Loading from '../components/Loading';
import { getUserList, getUserDetail, signUp, activateUser, updateUserDetail,
         resetPassword, deleteUser } from '../modules/user';

export default function UserManagementContainer({ goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getUserListState = useSelector(state => state.user.getUserList);
  const getUserDetailState = useSelector(state => state.user.getUserDetail);
  const signUpState = useSelector(state => state.user.signUp);
  const activateUserState = useSelector(state => state.user.activateUser);
  const deleteUserState = useSelector(state => state.user.deleteUser);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "A") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect(() => {
    if (signUpState.data || activateUserState.data || deleteUserState.data) {
      dispatch(getUserList());
    }
  }, [dispatch, signUpState.data, activateUserState.data, deleteUserState.data]);

  if (getUserListState.data) {
    return (
      <UserManagementComponent
        userList={getUserListState.data}
        userDetail={getUserDetailState.data}
        getUserDetail={id => dispatch(getUserDetail(id))}
        signUp={data => dispatch(signUp(data))}
        activateUser={data => dispatch(activateUser(data))}
        updateUserDetail={data => dispatch(updateUserDetail(data))}
        resetPassword={data => dispatch(resetPassword(data))}
        deleteUser={id => dispatch(deleteUser(id))}
      />
    );
  } else {
    return <Loading />;
  }
}
