import * as userAPI from '../api/user';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest, takeEvery } from 'redux-saga/effects';

/* 액션 타입 */

// 유저목록 조회
const GET_USER_LIST = 'GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
const GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR';

// 유저디테일 조회
const GET_USER_DETAIL = 'GET_USER_DETAIL';
const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR';

// 회원 가입
const SIGN_UP = 'SIGN_UP';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

// 유저 활성화
const ACTIVATE_USER = 'ACTIVATE_USER';
const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';

// 유저 정보 수정
const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
const UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS';
const UPDATE_USER_DETAIL_ERROR = 'UPDATE_USER_DETAIL_ERROR';

// 패스워드 리셋
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// 유저 삭제
const DELETE_USER = 'DELETE_USER';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const getUserList = () => ({ type: GET_USER_LIST });
export const getUserDetail = id => ({ type: GET_USER_DETAIL, payload: id });
export const signUp = data => ({ type: SIGN_UP, payload: data });
export const activateUser = data => ({ type: ACTIVATE_USER, payload: data });
export const updateUserDetail = data => ({ type: UPDATE_USER_DETAIL, payload: data });
export const resetPassword = data => ({ type: RESET_PASSWORD, payload: data });
export const deleteUser = id => ({ type: DELETE_USER, payload: id });

// 사가들을 합치기
export function* userSaga() {
    yield takeLatest(GET_USER_LIST, createPromiseSaga(GET_USER_LIST, userAPI.getUserList));
    yield takeLatest(GET_USER_DETAIL, createPromiseSaga(GET_USER_DETAIL, userAPI.getUserDetail));
    yield takeEvery(SIGN_UP, createPromiseSaga(SIGN_UP, userAPI.signUp));
    yield takeLatest(ACTIVATE_USER, createPromiseSaga(ACTIVATE_USER, userAPI.activateUser));
    yield takeLatest(UPDATE_USER_DETAIL, createPromiseSaga(UPDATE_USER_DETAIL, userAPI.updateUserDetail));
    yield takeLatest(RESET_PASSWORD, createPromiseSaga(RESET_PASSWORD, userAPI.resetPassword));
    yield takeLatest(DELETE_USER, createPromiseSaga(DELETE_USER, userAPI.deleteUser));
}

const initialState = {
    getUserList: reducerUtils.initial(null),
    getUserDetail: reducerUtils.initial(null),
    signUp: reducerUtils.initial(null),
    activateUser: reducerUtils.initial(null),
    updateUserDetail: reducerUtils.initial(null),
    resetPassword: reducerUtils.initial(null),
    deleteUser: reducerUtils.initial(null),
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case GET_USER_LIST:
        case GET_USER_LIST_SUCCESS:
        case GET_USER_LIST_ERROR:
            return handleAsyncActions(GET_USER_LIST, 'getUserList', false)(state, action);
        case GET_USER_DETAIL:
        case GET_USER_DETAIL_SUCCESS:
        case GET_USER_DETAIL_ERROR:
            return handleAsyncActions(GET_USER_DETAIL, 'getUserDetail', false)(state, action);
        case SIGN_UP:
        case SIGN_UP_SUCCESS:
        case SIGN_UP_ERROR:
            return handleAsyncActions(SIGN_UP, 'signUp', false)(state, action);
        case ACTIVATE_USER:
        case ACTIVATE_USER_SUCCESS:
        case ACTIVATE_USER_ERROR:
            return handleAsyncActions(ACTIVATE_USER, 'activateUser', false)(state, action);
        case UPDATE_USER_DETAIL:
        case UPDATE_USER_DETAIL_SUCCESS:
        case UPDATE_USER_DETAIL_ERROR:
            return handleAsyncActions(UPDATE_USER_DETAIL, 'updateUserDetail', false)(state, action);
        case RESET_PASSWORD:
        case RESET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_ERROR:
            return handleAsyncActions(RESET_PASSWORD, 'resetPassword', false)(state, action);
        case DELETE_USER:
        case DELETE_USER_SUCCESS:
        case DELETE_USER_ERROR:
            return handleAsyncActions(DELETE_USER, 'deleteUser', false)(state, action);
        default:
            return state;
    }
}
