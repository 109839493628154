import axios from './common';

export const createPacking = async data => {
  const response = await axios.post('/api/packings', data);
  return response.data;
};

export const getPackingList = async data => {
  const response = await axios.get(`/api/packings?date=${data.date}&packingType=${data.packingType}`);
  return response.data;
};

export const getMyPackingList = async () => {
  const response = await axios.get('/api/my-packings');
  return response.data;
};

export const deletePacking = async id => {
  const response = await axios.delete('/api/packings/' + id);
  return response.data;
};

export const getMyPackingColorList = async () => {
  const response = await axios.get('/api/my-packing-colors');
  return response.data;
};
