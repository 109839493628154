import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export default function FormDialog({ open, onEnter, onEntering, handleClose, onExiting, onExited, formElement }) {
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      onEnter={onEnter}
      onEntering={onEntering}
      onClose={handleClose}
      onExiting={onExiting}
      onExited={onExited}
    >
      {formElement}
    </Dialog>
  );
}