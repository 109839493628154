import React from 'react';
import { useHistory } from 'react-router-dom';
import DataControlContainer from '../containers/DataControl'

export default function DataControlPage() {
  const history = useHistory();

  return (
    <DataControlContainer
      goToDashboardHome={() => history.replace("/dashboard")}
      goToDailySubmission={userId => history.replace(`/dashboard/data-control/daily-submission?userId=${userId}`)}
      goToSubmissionHistory={userId => history.replace(`/dashboard/data-control/submission-history?userId=${userId}`)}
    />
  );
}
