import React from 'react';
import { useHistory } from 'react-router-dom';
import UserManagementContainer from '../containers/UserManagement'

export default function UserManagementPage() {
  const history = useHistory();

  return (
    <UserManagementContainer
    goToDashboardHome={() => history.replace("/dashboard")}
    />
  );
}
