import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SnackBar from './SnackBar';
import Step0ForFarmer from './Step0ForFarmer';
import Step1ForFarmer from './Step1ForFarmer';
import Step2ForFarmer from './Step2ForFarmer';

const LL = {
  SELECT: 'Lựa chọn',
  FILL_IN: 'Điền vào',
  REVIEW: 'Xem lại',
  ALERT_MSG: 'Điền vào ô trống',
  WORK_DATE_ALERT_MSG: 'Ngày thực hiện đã quá 2 ngày',
  DAILY_SUBMISSION: 'Báo cáo ngày',
  THANK_YOU_FOR_YOUR_SUBMISSION: 'Cảm ơn đã nộp',
  GO_BACK: 'Quay lại',
  SUBMIT_ERROR: 'LỖI! Dữ liệu này đã được nộp trước đó.',
  SUBMIT: 'Nộp',
  NEXT: 'Tiếp theo',
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [LL.SELECT, LL.FILL_IN, LL.REVIEW];

const getWorkDate = () => {
  const offsetDate = (date, offset) => new Date(date.getTime() - offset);
  const formatter = date => date.toISOString().split('T')[0];

  const yourDate = new Date();
  const maxDate = offsetDate(yourDate, yourDate.getTimezoneOffset()*60*1000);
  const minDate = offsetDate(yourDate, 2*24*60*60*1000);

  return { minDate: formatter(minDate), maxDate: formatter(maxDate) };
};

export default function DailySubmissionComponent({ goToDashboardHome, userDetail, tabList, ribbonColorList, materialList, createHarvest, isSuccess }) {
  const classes = useStyles();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);

  const tabs = tabList.map(item => item.name);
  const getTabId = tabName => tabList.filter(item => item.name === tabName)[0].id;
  const getTabInput = tabIndex => {
    const tabName = tabs[tabIndex];
    const tabId = getTabId(tabName);
    return {
      id: tabId,
      name: tabName,
      workDate: workDate,
      manHour: '',
      numOfMan: '',
      ribbonColor: '',
      ribbonColorSub: '',
      otherName: '',
      lot: userDetail.lot.filter((_, index) => selectedLots[index]).map(lotName => (
        {
          name: lotName,
          actualHa: '',
          actualBunches: '',
        }
      )),
      materials: [],
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedTaps, setSelectedTaps] = React.useState([]);
  const [selectedLots, setSelectedLots] = React.useState(userDetail.lot.map(() => false));
  
  const workDateObj = getWorkDate();
  const [workDate, setWorkDate] = React.useState(workDateObj.maxDate);

  const [insertData, setInsertData] = React.useState([]);

  const validateInput = () => {
    for (const tabItem of insertData) {
      if (tabItem.manHour === '' || tabItem.numOfMan === '') {
        return false;
      }
      if (tabItem.id === 0 && tabItem.ribbonColor === '') {
        return false;
      }
      if (tabItem.id === 1 && tabItem.otherName === '') {
        return false;
      }

      for (const lotItem of tabItem.lot) {
        if (lotItem.actualHa === '' || lotItem.actualBunches === '') {
          return false;
        }
      }

      for (const matItem of tabItem.materials) {
        if (matItem.value === '' || matItem.metric === '') {
          return false;
        }
      }
    }

    return true;
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (selectedTaps.length === 0 || selectedLots.every((bool) => bool === false)) {
        setAlertMsg(LL.ALERT_MSG);
        setAlertOpen(true);
        return;
      }
      if (workDate < workDateObj.minDate || workDate > workDateObj.maxDate) {
        setAlertMsg(LL.WORK_DATE_ALERT_MSG);
        setAlertOpen(true);
        return;
      }
      setInsertData(selectedTaps.map(getTabInput));
    } else if (activeStep === 1) {
      if (!validateInput()) {
        setAlertMsg(LL.ALERT_MSG);
        setAlertOpen(true);
        return;
      }
    } else if (activeStep === 2) {
      createHarvest(insertData);
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setSelectedTaps([]);
    }
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step0ForFarmer
            userDetail={userDetail}
            tabs={tabs}
            selectedTaps={selectedTaps}
            setSelectedTaps={setSelectedTaps}
            selectedLots={selectedLots}
            setSelectedLots={setSelectedLots}
            workDateObj={workDateObj}
            workDate={workDate}
            setWorkDate={setWorkDate}
          />
        );
      case 1:
        return (
          <Step1ForFarmer
            insertData={insertData}
            ribbonColorList={ribbonColorList}
            setInsertData={setInsertData}
            materialList={materialList}
          />
        );
      case 2:
        return (
          <Step2ForFarmer
            insertData={insertData}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <SnackBar
            open={alertOpen}
            setOpen={setAlertOpen}
            severity={"error"}
            msg={alertMsg}
          />
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              {LL.DAILY_SUBMISSION}
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? isSuccess ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    {LL.THANK_YOU_FOR_YOUR_SUBMISSION}
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => goToDashboardHome()}>
                    {LL.GO_BACK}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    {LL.SUBMIT_ERROR}
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => goToDashboardHome()}>
                    {LL.GO_BACK}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        {LL.GO_BACK}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? LL.SUBMIT : LL.NEXT}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
