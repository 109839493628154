import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SignInComponent from '../components/SignIn';
import { signIn } from '../modules/auth';

export default function SignInContainer({ goToPrevPage }) {
  const { data, error } = useSelector(state => state.auth.authInfo);
  const dispatch = useDispatch();
  const tokenInfo = data && data.accessToken;

  useEffect(() => {
    if (tokenInfo) goToPrevPage();
  }, [dispatch, tokenInfo, goToPrevPage]);

  return (
    <SignInComponent
      isError={error !== null}
      onSubmit={credentials => dispatch(signIn(credentials))}
    />
  );
}
