import axios from './common';

export const getTabList = async () => {
  const response = await axios.get('/api/tabs');
  return response.data;
};

export const getActivityList = async () => {
  const response = await axios.get('/api/activities');
  return response.data;
};

export const getMaterialList = async () => {
  const response = await axios.get('/api/materials');
  return response.data;
};

export const getRibbonColorList = async () => {
  const response = await axios.get('/api/ribbon-colors');
  return response.data;
};

export const getSkuTypeList = async () => {
  const response = await axios.get('/api/sku-types');
  return response.data;
};

export const getBoxTypeList = async () => {
  const response = await axios.get('/api/box-types');
  return response.data;
};

export const createTab = async data => {
  const response = await axios.post('/api/tabs', data);
  return response.data;
};

export const createActivity = async data => {
  const response = await axios.post('/api/activities', data);
  return response.data;
};

export const createMaterial = async data => {
  const response = await axios.post('/api/materials', data);
  return response.data;
};

export const createRibbonColor = async data => {
  const response = await axios.post('/api/ribbon-colors', data);
  return response.data;
};

export const createSkuType = async data => {
  const response = await axios.post('/api/sku-types', data);
  return response.data;
};

export const createBoxType = async data => {
  const response = await axios.post('/api/box-types', data);
  return response.data;
};

export const deleteTab = async id => {
  const response = await axios.delete('/api/tabs/' + id);
  return response.data;
};

export const deleteActivity = async id => {
  const response = await axios.delete('/api/activities/' + id);
  return response.data;
};

export const deleteMaterial = async id => {
  const response = await axios.delete('/api/materials/' + id);
  return response.data;
};

export const deleteRibbonColor = async id => {
  const response = await axios.delete('/api/ribbon-colors/' + id);
  return response.data;
};

export const deleteSkuType = async id => {
  const response = await axios.delete('/api/sku-types/' + id);
  return response.data;
};

export const deleteBoxType = async id => {
  const response = await axios.delete('/api/box-types/' + id);
  return response.data;
};
