import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataViewerComponent from '../components/DataViewer';
import { getHarvestList, getHarvestMaterialList } from '../modules/harvest';
import { getPackingList } from '../modules/packing';

export default function DataViewerContainer({ goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getHarvestListState = useSelector(state => state.harvest.getHarvestList);
  const getHarvestMaterialListState = useSelector(state => state.harvest.getHarvestMaterialList);
  const getPackingListState = useSelector(state => state.packing.getPackingList);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  const tempMenuList = ["C - Lot", "C - Material", "D - SKU", "D - Ribbon", "D - Activity"];
  const menuList = userRole === "B2" ? tempMenuList.slice(2) : tempMenuList;

  useEffect(() => {
    if (userRole !== "B1" && userRole !== "B2" && userRole !== "B3") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  return (
    <DataViewerComponent
      menuList={menuList}
      getHarvestList={date => dispatch(getHarvestList(date))}
      getHarvestMaterialList={date => dispatch(getHarvestMaterialList(date))}
      getPackingList={data => dispatch(getPackingList(data))}
      dataLoading={getHarvestListState.loading || getHarvestMaterialListState.loading || getPackingListState.loading}
      harvestData={getHarvestListState.data}
      harvestMaterialData={getHarvestMaterialListState.data}
      packingData={getPackingListState.data}
    />
  );
}
