import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function SubmissionRow({ data, onDeleteClick }) {
  const classes = useRowStyles();
  const diffDate = ((new Date()).getTime() - (new Date(data.submitDate)).getTime()) / (24 * 60 * 60 * 1000);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center"></TableCell>
        <TableCell align="center">{(new Date(data.submitDate)).toLocaleString()}</TableCell>
        <TableCell align="center">{data.workDate}</TableCell>
        <TableCell align="center" colSpan={2}>{data.tabName}</TableCell>
        <TableCell align="center">{data.numOfMan}</TableCell>
        <TableCell align="center">{data.manHour}</TableCell>
        {
          data.tabId === 0 ? (
            <TableCell align="center">{data.ribbonColor}</TableCell>
          ) : data.tabId === 1 ? (
            <TableCell align="center">{data.otherName}</TableCell>
          ) : (
            <TableCell align="center"></TableCell>
          )
        }
        <TableCell align="center">
        {
          diffDate < 1 ? (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button color="secondary" onClick={() => onDeleteClick(data.id)}>delete</Button>
            </ButtonGroup>
          ) : null
        }
        </TableCell>
      </TableRow>
      {
        data.lot.map((lot, index) => (
          <TableRow className={classes.root} key={index}>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">{lot.lot}</TableCell>
            <TableCell align="center">{lot.actualHa}</TableCell>
            <TableCell align="center">{lot.actualBunches}</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        ))
      }
      {
        data.materials.map((material, index) => (
          <TableRow className={classes.root} key={index}>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">{material.material}</TableCell>
            <TableCell align="center">{material.value}</TableCell>
            <TableCell align="center">{material.metric}</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        ))
      }
    </React.Fragment>
  );
}
