import axios from "axios";
import { getAuthInfo, removeAuthInfo } from "../lib/authUtils";

const instance = axios.create();

instance.interceptors.request.use(
    config => {
        const token = getAuthInfo().accessToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            removeAuthInfo();
            window.location.reload();
            return Promise.resolve(true);
        }
        return Promise.reject(error);
    }
)

export default instance;
