import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataManagementComponent1 from '../components/DataManagement1';
import { getTabList, getRibbonColorList, getSkuTypeList, getBoxTypeList, createTab, createRibbonColor,
        createSkuType, createBoxType, deleteTab, deleteRibbonColor, deleteSkuType, deleteBoxType } from '../modules/tab';

export default function DataManagementContainer1({ goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getTabListState = useSelector(state => state.tab.getTabList);
  const getRibbonColorListState = useSelector(state => state.tab.getRibbonColorList);
  const getSkuTypeListState = useSelector(state => state.tab.getSkuTypeList);
  const getBoxTypeListState = useSelector(state => state.tab.getBoxTypeList);
  const createTabState = useSelector(state => state.tab.createTab);
  const createRibbonColorState = useSelector(state => state.tab.createRibbonColor);
  const createSkuTypeState = useSelector(state => state.tab.createSkuType);
  const createBoxTypeState = useSelector(state => state.tab.createBoxType);
  const deleteTabState = useSelector(state => state.tab.deleteTab);
  const deleteRibbonColorState = useSelector(state => state.tab.deleteRibbonColor);
  const deleteSkuTypeState = useSelector(state => state.tab.deleteSkuType);
  const deleteBoxTypeState = useSelector(state => state.tab.deleteBoxType);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "A") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    dispatch(getTabList());
    dispatch(getRibbonColorList());
    dispatch(getSkuTypeList());
    dispatch(getBoxTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (createTabState.data || deleteTabState.data) {
      dispatch(getTabList());
    }
  }, [dispatch, createTabState.data, deleteTabState.data]);

  useEffect(() => {
    if (createRibbonColorState.data || deleteRibbonColorState.data) {
      dispatch(getRibbonColorList());
    }
  }, [dispatch, createRibbonColorState.data, deleteRibbonColorState.data]);

  useEffect(() => {
    if (createSkuTypeState.data || deleteSkuTypeState.data) {
      dispatch(getSkuTypeList());
    }
  }, [dispatch, createSkuTypeState.data, deleteSkuTypeState.data]);

  useEffect(() => {
    if (createBoxTypeState.data || deleteBoxTypeState.data) {
      dispatch(getBoxTypeList());
    }
  }, [dispatch, createBoxTypeState.data, deleteBoxTypeState.data]);

  return (
    <DataManagementComponent1
      tabList={getTabListState.data}
      ribbonColorList={getRibbonColorListState.data}
      skuTypeList={getSkuTypeListState.data}
      boxTypeList={getBoxTypeListState.data}
      createTab={data => dispatch(createTab(data))}
      createRibbonColor={data => dispatch(createRibbonColor(data))}
      createSkuType={data => dispatch(createSkuType(data))}
      createBoxType={data => dispatch(createBoxType(data))}
      deleteTab={id => dispatch(deleteTab(id))}
      deleteRibbonColor={id => dispatch(deleteRibbonColor(id))}
      deleteSkuType={id => dispatch(deleteSkuType(id))}
      deleteBoxType={id => dispatch(deleteBoxType(id))}
    />
  );
}
