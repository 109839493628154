import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TabList from './TabList';

const LL = {
  SELECT_DATE: 'Chọn ngày',
  SUBMISSION_DATE: 'Ngày nộp dữ liệu',
  WORK_DATE: 'Ngày thực hiện',
  WEEK: 'Tuần',
  PERIOD: 'Giai đoạn',
  LOCATION: 'Vị trí',
  SELECT_ACTIVITES: 'Chọn hạng mục',
  SELECT_LOTS: 'Chọn lô',
};

const getPeriod = weekNum => {
  return parseInt((weekNum - 1) / 4) + 1;
};

export default function Step0({ userDetail, tabs, selectedTaps, setSelectedTaps, selectedLots, setSelectedLots, workDateObj, workDate, setWorkDate }) {
  const defaultWeekNum = moment(workDate).add(-1, 'days').week();
  const [weekNum, setWeekNum] = React.useState(defaultWeekNum);
  const [period, setPeriod] = React.useState(getPeriod(defaultWeekNum));

  const handleWorkDate = e => {
    const selectedDate = e.target.value;
    const pWeekNum = moment(selectedDate).add(-1, 'days').week();
    setWeekNum(pWeekNum);
    setPeriod(getPeriod(pWeekNum));
    setWorkDate(selectedDate);
  };

  const handleLotChange = e => {
    const copyArr = selectedLots.slice();
    copyArr[e.target.value] = e.target.checked;
    setSelectedLots(copyArr);
  };
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {LL.SELECT_DATE}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="submitDate"
            name="submitDate"
            label={LL.SUBMISSION_DATE}
            fullWidth
            value={workDateObj.maxDate}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="workDate"
            type="date"
            name="workDate"
            label={LL.WORK_DATE}
            inputProps={{min: workDateObj.minDate, max: workDateObj.maxDate}}
            value={workDate}
            onChange={handleWorkDate}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
            id="week"
            name="week"
            label={LL.WEEK}
            fullWidth
            value={weekNum}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="period"
            name="period"
            label={LL.PERIOD}
            fullWidth
            value={period}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="location"
            name="location"
            label={LL.LOCATION}
            fullWidth
            value={userDetail.farm}
            disabled={true}
          />
        </Grid>
      </Grid>
      <br/><br/>
      <Typography variant="h6" gutterBottom>
        {LL.SELECT_ACTIVITES}
      </Typography>
      {
        tabs.length === 0 ? null : (
          <TabList
            tabs={tabs}
            right={selectedTaps}
            setRight={setSelectedTaps}
          />
        )
      }
      <br/><br/>
      <Typography variant="h6" gutterBottom>
        {LL.SELECT_LOTS}
      </Typography>
      <FormGroup row>
      {
        userDetail.lot.map((lotName, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedLots[index]}
                  onChange={handleLotChange}
                  value={index}
                />
              }
              label={lotName}
            />
        ))
      }
      </FormGroup>
    </React.Fragment>
  );
}