import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Loading from '../components/Loading';
import FormDialog from '../components/DataDialog';
import DeleteForm from '../components/DataDeleteForm';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DataManagementTable({ tabList, ribbonColorList, skuTypeList, boxTypeList, createTab, createRibbonColor, createSkuType,
                                              createBoxType, deleteTab, deleteRibbonColor, deleteSkuType, deleteBoxType }) {
  const classes = useStyles();
  const [formOpen, setFormOpen] = React.useState(false);
  const [formElement, setFormElement] = React.useState(null);
  const [menu, setMenu] = React.useState('tab');
  const dataList = menu === 'tab' ? tabList : menu === 'ribbonColor' ? ribbonColorList : menu === 'skuType' ? skuTypeList : menu === 'boxType' ? boxTypeList : null;
  const [inputData, setInputData] = React.useState('');

  const handleMenuChange = e => {
    const newMenu = e.target.value;
    setMenu(newMenu);
    setInputData('');
  };

  const handleAddClick = () => {
    switch (menu) {
      case 'tab':
        createTab({'name': inputData});
        break;
      case 'ribbonColor':
        createRibbonColor({'color': inputData});
        break;
      case 'skuType':
        createSkuType({'sku': inputData});
        break;
      case 'boxType':
        createBoxType({'box': inputData});
        break;
      default:
        break;
    }
    setInputData('');
  };

  const handleDeleteClick = row => {
    const item = menu === 'tab' ? row.name : menu === 'ribbonColor' ? row.color : menu === 'skuType' ? row.sku : menu === 'boxType' ? row.box : null;
    const deleteData = menu === 'tab' ? deleteTab : menu === 'ribbonColor' ? deleteRibbonColor : menu === 'skuType' ? deleteSkuType : menu === 'boxType' ? deleteBoxType : null;
    setFormOpen(true);
    setFormElement(
      <DeleteForm
        id={row.id}
        item={item}
        deleteData={deleteData}
        handleClose={handleFormClose}
      />
    );
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              Data Management 1
            </Typography>
            <FormDialog
              open={formOpen}
              handleClose={handleFormClose}
              formElement={formElement}
            />
            <FormControl className={classes.formControl}>
              <InputLabel>Data Type</InputLabel>
              <Select
                value={menu}
                style={{width: 200}}
                onChange={handleMenuChange}
              >
                <MenuItem value='tab'>Activity - C</MenuItem>
                <MenuItem value='ribbonColor'>Ribbon Color</MenuItem>
                <MenuItem value='skuType'>Sku Type</MenuItem>
                <MenuItem value='boxType'>Box Type</MenuItem>
              </Select>
            </FormControl>
            <div style={{textAlign: 'right'}}>
            <TextField
                label="Add Item"
                value={inputData}
                style={{width: '200px'}}
                onChange={e => setInputData(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{verticalAlign: 'bottom'}}
                disabled={inputData === ''}
                onClick={handleAddClick}
              >
                Add
              </Button>
            </div>
            {
              dataList === null ? <Loading /> : (
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">Item</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {
                          dataList.map((row) => (
                            <TableRow className={classes.tableRow} key={row.id}>
                              <TableCell>
                              </TableCell>
                              <TableCell align="center">
                                {
                                  menu === 'tab' ? row.name :
                                  menu === 'ribbonColor' ? row.color :
                                  menu === 'skuType' ? row.sku :
                                  menu === 'boxType' ? row.box :
                                  null
                                }
                              </TableCell>
                              <TableCell align="center">
                                {
                                  menu === 'tab' && [0,1,5].includes(row.id) ? <></> : (
                                    <ButtonGroup size="small" aria-label="small outlined button group">
                                      <Button color="secondary" onClick={() => handleDeleteClick(row)}>delete</Button>
                                    </ButtonGroup>
                                  )
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                  </Table>
                </TableContainer>
              )
            }
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
