import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataManagementComponent2 from '../components/DataManagement2';
import { getActivityList, getMaterialList, createActivity, createMaterial, deleteActivity, deleteMaterial } from '../modules/tab';

export default function DataManagementContainer1({ goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getActivityListState = useSelector(state => state.tab.getActivityList);
  const getMaterialListState = useSelector(state => state.tab.getMaterialList);
  const createActivityState = useSelector(state => state.tab.createActivity);
  const createMaterialState = useSelector(state => state.tab.createMaterial);
  const deleteActivityState = useSelector(state => state.tab.deleteActivity);
  const deleteMaterialState = useSelector(state => state.tab.deleteMaterial);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "A") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    dispatch(getActivityList());
    dispatch(getMaterialList());
  }, [dispatch]);

  useEffect(() => {
    if (createActivityState.data || deleteActivityState.data) {
      dispatch(getActivityList());
    }
  }, [dispatch, createActivityState.data, deleteActivityState.data]);

  useEffect(() => {
    if (createMaterialState.data || deleteMaterialState.data) {
      dispatch(getMaterialList());
    }
  }, [dispatch, createMaterialState.data, deleteMaterialState.data]);

  return (
    <DataManagementComponent2
      activityList={getActivityListState.data}
      materialList={getMaterialListState.data}
      createActivity={data => dispatch(createActivity(data))}
      createMaterial={data => dispatch(createMaterial(data))}
      deleteActivity={id => dispatch(deleteActivity(id))}
      deleteMaterial={id => dispatch(deleteMaterial(id))}
    />
  );
}
