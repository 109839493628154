import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function UserRow(props) {
  const { row, onModifyClick, onActivateClick, onPasswordResetClick, onDeleteClick } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell align="center">{row.userName}</TableCell>
        <TableCell align="center">{row.grade}</TableCell>
        <TableCell align="center">
        {
          row.grade === 'N' ? (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button color="primary" onClick={() => onActivateClick(row.email)}>activate</Button>
              <Button color="secondary" onClick={() => onDeleteClick(row.email)}>delete</Button>
            </ButtonGroup>
          ) : row.grade !== 'A' ? (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button color="primary" onClick={() => onModifyClick(row.email)}>update</Button>
              <Button color="primary" onClick={() => onPasswordResetClick(row.email)}>reset pw</Button>
              <Button color="secondary" onClick={() => onDeleteClick(row.email)}>delete</Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup size="small" aria-label="small outlined button group">
            </ButtonGroup>
          )
        }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
