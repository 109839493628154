import * as tabAPI from '../api/tab';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest } from 'redux-saga/effects';

/* 액션 타입 */

// 탭목록 조회
const GET_TAB_LIST = 'GET_TAB_LIST';
const GET_TAB_LIST_SUCCESS = 'GET_TAB_LIST_SUCCESS';
const GET_TAB_LIST_ERROR = 'GET_TAB_LIST_ERROR';

// 액티비티목록 조회
const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS';
const GET_ACTIVITY_LIST_ERROR = 'GET_ACTIVITY_LIST_ERROR';

// 재료목록 조회
const GET_MATERIAL_LIST = 'GET_MATERIAL_LIST';
const GET_MATERIAL_LIST_SUCCESS = 'GET_MATERIAL_LIST_SUCCESS';
const GET_MATERIAL_LIST_ERROR = 'GET_MATERIAL_LIST_ERROR';

// 리본칼라 목록 조회
const GET_RIBBON_COLOR_LIST = 'GET_RIBBON_COLOR_LIST';
const GET_RIBBON_COLOR_LIST_SUCCESS = 'GET_RIBBON_COLOR_LIST_SUCCESS';
const GET_RIBBON_COLOR_LIST_ERROR = 'GET_RIBBON_COLOR_LIST_ERROR';

// sku 목록 조회
const GET_SKU_TYPE_LIST = 'GET_SKU_TYPE_LIST';
const GET_SKU_TYPE_LIST_SUCCESS = 'GET_SKU_TYPE_LIST_SUCCESS';
const GET_SKU_TYPE_LIST_ERROR = 'GET_SKU_TYPE_LIST_ERROR';

// box 목록 조회
const GET_BOX_TYPE_LIST = 'GET_BOX_TYPE_LIST';
const GET_BOX_TYPE_LIST_SUCCESS = 'GET_BOX_TYPE_LIST_SUCCESS';
const GET_BOX_TYPE_LIST_ERROR = 'GET_BOX_TYPE_LIST_ERROR';

// 탭 생성
const CREATE_TAB = 'CREATE_TAB';
const CREATE_TAB_SUCCESS = 'CREATE_TAB_SUCCESS';
const CREATE_TAB_ERROR = 'CREATE_TAB_ERROR';

// 액티비티 생성
const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';

// 재료 생성
const CREATE_MATERIAL = 'CREATE_MATERIAL';
const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
const CREATE_MATERIAL_ERROR = 'CREATE_MATERIAL_ERROR';

// 리본칼라 생성
const CREATE_RIBBON_COLOR = 'CREATE_RIBBON_COLOR';
const CREATE_RIBBON_COLOR_SUCCESS = 'CREATE_RIBBON_COLOR_SUCCESS';
const CREATE_RIBBON_COLOR_ERROR = 'CREATE_RIBBON_COLOR_ERROR';

// sku 생성
const CREATE_SKU_TYPE = 'CREATE_SKU_TYPE';
const CREATE_SKU_TYPE_SUCCESS = 'CREATE_SKU_TYPE_SUCCESS';
const CREATE_SKU_TYPE_ERROR = 'CREATE_SKU_TYPE_ERROR';

// box 생성
const CREATE_BOX_TYPE = 'CREATE_BOX_TYPE';
const CREATE_BOX_TYPE_SUCCESS = 'CREATE_BOX_TYPE_SUCCESS';
const CREATE_BOX_TYPE_ERROR = 'CREATE_BOX_TYPE_ERROR';

// 탭 삭제
const DELETE_TAB = 'DELETE_TAB';
const DELETE_TAB_SUCCESS = 'DELETE_TAB_SUCCESS';
const DELETE_TAB_ERROR = 'DELETE_TAB_ERROR';

// 액티비티 삭제
const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';

// 재료 삭제
const DELETE_MATERIAL = 'DELETE_MATERIAL';
const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS';
const DELETE_MATERIAL_ERROR = 'DELETE_MATERIAL_ERROR';

// 리본칼라 삭제
const DELETE_RIBBON_COLOR = 'DELETE_RIBBON_COLOR';
const DELETE_RIBBON_COLOR_SUCCESS = 'DELETE_RIBBON_COLOR_SUCCESS';
const DELETE_RIBBON_COLOR_ERROR = 'DELETE_RIBBON_COLOR_ERROR';

// sku 삭제
const DELETE_SKU_TYPE = 'DELETE_SKU_TYPE';
const DELETE_SKU_TYPE_SUCCESS = 'DELETE_SKU_TYPE_SUCCESS';
const DELETE_SKU_TYPE_ERROR = 'DELETE_SKU_TYPE_ERROR';

// box 삭제
const DELETE_BOX_TYPE = 'DELETE_BOX_TYPE';
const DELETE_BOX_TYPE_SUCCESS = 'DELETE_BOX_TYPE_SUCCESS';
const DELETE_BOX_TYPE_ERROR = 'DELETE_BOX_TYPE_ERROR';

export const getTabList = () => ({ type: GET_TAB_LIST });
export const getActivityList = () => ({ type: GET_ACTIVITY_LIST });
export const getMaterialList = () => ({ type: GET_MATERIAL_LIST });
export const getRibbonColorList = () => ({ type: GET_RIBBON_COLOR_LIST });
export const getSkuTypeList = () => ({ type: GET_SKU_TYPE_LIST });
export const getBoxTypeList = () => ({ type: GET_BOX_TYPE_LIST });
export const createTab = data => ({ type: CREATE_TAB, payload: data });
export const createActivity = data => ({ type: CREATE_ACTIVITY, payload: data });
export const createMaterial = data => ({ type: CREATE_MATERIAL, payload: data });
export const createRibbonColor = data => ({ type: CREATE_RIBBON_COLOR, payload: data });
export const createSkuType = data => ({ type: CREATE_SKU_TYPE, payload: data });
export const createBoxType = data => ({ type: CREATE_BOX_TYPE, payload: data });
export const deleteTab = id => ({ type: DELETE_TAB, payload: id });
export const deleteActivity = id => ({ type: DELETE_ACTIVITY, payload: id });
export const deleteMaterial = id => ({ type: DELETE_MATERIAL, payload: id });
export const deleteRibbonColor = id => ({ type: DELETE_RIBBON_COLOR, payload: id });
export const deleteSkuType = id => ({ type: DELETE_SKU_TYPE, payload: id });
export const deleteBoxType = id => ({ type: DELETE_BOX_TYPE, payload: id });

// 사가들을 합치기
export function* tabSaga() {
    yield takeLatest(GET_TAB_LIST, createPromiseSaga(GET_TAB_LIST, tabAPI.getTabList));
    yield takeLatest(GET_ACTIVITY_LIST, createPromiseSaga(GET_ACTIVITY_LIST, tabAPI.getActivityList));
    yield takeLatest(GET_MATERIAL_LIST, createPromiseSaga(GET_MATERIAL_LIST, tabAPI.getMaterialList));
    yield takeLatest(GET_RIBBON_COLOR_LIST, createPromiseSaga(GET_RIBBON_COLOR_LIST, tabAPI.getRibbonColorList));
    yield takeLatest(GET_SKU_TYPE_LIST, createPromiseSaga(GET_SKU_TYPE_LIST, tabAPI.getSkuTypeList));
    yield takeLatest(GET_BOX_TYPE_LIST, createPromiseSaga(GET_BOX_TYPE_LIST, tabAPI.getBoxTypeList));
    yield takeLatest(CREATE_TAB, createPromiseSaga(CREATE_TAB, tabAPI.createTab));
    yield takeLatest(CREATE_ACTIVITY, createPromiseSaga(CREATE_ACTIVITY, tabAPI.createActivity));
    yield takeLatest(CREATE_MATERIAL, createPromiseSaga(CREATE_MATERIAL, tabAPI.createMaterial));
    yield takeLatest(CREATE_RIBBON_COLOR, createPromiseSaga(CREATE_RIBBON_COLOR, tabAPI.createRibbonColor));
    yield takeLatest(CREATE_SKU_TYPE, createPromiseSaga(CREATE_SKU_TYPE, tabAPI.createSkuType));
    yield takeLatest(CREATE_BOX_TYPE, createPromiseSaga(CREATE_BOX_TYPE, tabAPI.createBoxType));
    yield takeLatest(DELETE_TAB, createPromiseSaga(DELETE_TAB, tabAPI.deleteTab));
    yield takeLatest(DELETE_ACTIVITY, createPromiseSaga(DELETE_ACTIVITY, tabAPI.deleteActivity));
    yield takeLatest(DELETE_MATERIAL, createPromiseSaga(DELETE_MATERIAL, tabAPI.deleteMaterial));
    yield takeLatest(DELETE_RIBBON_COLOR, createPromiseSaga(DELETE_RIBBON_COLOR, tabAPI.deleteRibbonColor));
    yield takeLatest(DELETE_SKU_TYPE, createPromiseSaga(DELETE_SKU_TYPE, tabAPI.deleteSkuType));
    yield takeLatest(DELETE_BOX_TYPE, createPromiseSaga(DELETE_BOX_TYPE, tabAPI.deleteBoxType));
}

const initialState = {
    getTabList: reducerUtils.initial(null),
    getActivityList: reducerUtils.initial(null),
    getMaterialList: reducerUtils.initial(null),
    getRibbonColorList: reducerUtils.initial(null),
    getSkuTypeList: reducerUtils.initial(null),
    getBoxTypeList: reducerUtils.initial(null),
    createTab: reducerUtils.initial(null),
    createActivity: reducerUtils.initial(null),
    createMaterial: reducerUtils.initial(null),
    createRibbonColor: reducerUtils.initial(null),
    createSkuType: reducerUtils.initial(null),
    createBoxType: reducerUtils.initial(null),
    deleteTab: reducerUtils.initial(null),
    deleteActivity: reducerUtils.initial(null),
    deleteMaterial: reducerUtils.initial(null),
    deleteRibbonColor: reducerUtils.initial(null),
    deleteSkuType: reducerUtils.initial(null),
    deleteBoxType: reducerUtils.initial(null),
};

export default function tab(state = initialState, action) {
    switch (action.type) {
        case GET_TAB_LIST:
        case GET_TAB_LIST_SUCCESS:
        case GET_TAB_LIST_ERROR:
            return handleAsyncActions(GET_TAB_LIST, 'getTabList', false)(state, action);
        case GET_ACTIVITY_LIST:
        case GET_ACTIVITY_LIST_SUCCESS:
        case GET_ACTIVITY_LIST_ERROR:
            return handleAsyncActions(GET_ACTIVITY_LIST, 'getActivityList', false)(state, action);
        case GET_MATERIAL_LIST:
        case GET_MATERIAL_LIST_SUCCESS:
        case GET_MATERIAL_LIST_ERROR:
            return handleAsyncActions(GET_MATERIAL_LIST, 'getMaterialList', false)(state, action);
        case GET_RIBBON_COLOR_LIST:
        case GET_RIBBON_COLOR_LIST_SUCCESS:
        case GET_RIBBON_COLOR_LIST_ERROR:
            return handleAsyncActions(GET_RIBBON_COLOR_LIST, 'getRibbonColorList', false)(state, action);
        case GET_SKU_TYPE_LIST:
        case GET_SKU_TYPE_LIST_SUCCESS:
        case GET_SKU_TYPE_LIST_ERROR:
            return handleAsyncActions(GET_SKU_TYPE_LIST, 'getSkuTypeList', false)(state, action);
        case GET_BOX_TYPE_LIST:
        case GET_BOX_TYPE_LIST_SUCCESS:
        case GET_BOX_TYPE_LIST_ERROR:
            return handleAsyncActions(GET_BOX_TYPE_LIST, 'getBoxTypeList', false)(state, action);
        case CREATE_TAB:
        case CREATE_TAB_SUCCESS:
        case CREATE_TAB_ERROR:
            return handleAsyncActions(CREATE_TAB, 'createTab', false)(state, action);
        case CREATE_ACTIVITY:
        case CREATE_ACTIVITY_SUCCESS:
        case CREATE_ACTIVITY_ERROR:
            return handleAsyncActions(CREATE_ACTIVITY, 'createActivity', false)(state, action);
        case CREATE_MATERIAL:
        case CREATE_MATERIAL_SUCCESS:
        case CREATE_MATERIAL_ERROR:
            return handleAsyncActions(CREATE_MATERIAL, 'createMaterial', false)(state, action);
        case CREATE_RIBBON_COLOR:
        case CREATE_RIBBON_COLOR_SUCCESS:
        case CREATE_RIBBON_COLOR_ERROR:
            return handleAsyncActions(CREATE_RIBBON_COLOR, 'createRibbonColor', false)(state, action);
        case CREATE_SKU_TYPE:
        case CREATE_SKU_TYPE_SUCCESS:
        case CREATE_SKU_TYPE_ERROR:
            return handleAsyncActions(CREATE_SKU_TYPE, 'createSkuType', false)(state, action);
        case CREATE_BOX_TYPE:
        case CREATE_BOX_TYPE_SUCCESS:
        case CREATE_BOX_TYPE_ERROR:
            return handleAsyncActions(CREATE_BOX_TYPE, 'createBoxType', false)(state, action);
        case DELETE_TAB:
        case DELETE_TAB_SUCCESS:
        case DELETE_TAB_ERROR:
            return handleAsyncActions(DELETE_TAB, 'deleteTab', false)(state, action);
        case DELETE_ACTIVITY:
        case DELETE_ACTIVITY_SUCCESS:
        case DELETE_ACTIVITY_ERROR:
            return handleAsyncActions(DELETE_ACTIVITY, 'deleteActivity', false)(state, action);
        case DELETE_MATERIAL:
        case DELETE_MATERIAL_SUCCESS:
        case DELETE_MATERIAL_ERROR:
            return handleAsyncActions(DELETE_MATERIAL, 'deleteMaterial', false)(state, action);
        case DELETE_RIBBON_COLOR:
        case DELETE_RIBBON_COLOR_SUCCESS:
        case DELETE_RIBBON_COLOR_ERROR:
            return handleAsyncActions(DELETE_RIBBON_COLOR, 'deleteRibbonColor', false)(state, action);
        case DELETE_SKU_TYPE:
        case DELETE_SKU_TYPE_SUCCESS:
        case DELETE_SKU_TYPE_ERROR:
            return handleAsyncActions(DELETE_SKU_TYPE, 'deleteSkuType', false)(state, action);
        case DELETE_BOX_TYPE:
        case DELETE_BOX_TYPE_SUCCESS:
        case DELETE_BOX_TYPE_ERROR:
            return handleAsyncActions(DELETE_BOX_TYPE, 'deleteBoxType', false)(state, action);
        default:
            return state;
    }
}
