import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const LL = {
  WORK_DATE: 'Ngày thực hiện',
  ACTUAL_HA: 'Diện tích thực hien',
  ACTUAL_BUNCHES: 'Số lượng buồng thực tế',
  MAN_HOUR: 'Man Hour',
  NUM_OF_MAN: 'Số luong công nhân',
  SELECT: 'Lựa chọn',
  RIBBON_COLOR: 'Màu dây',
  ACTIVITY_DETAILS: 'Chi tiết cv',
  LOT_DETAILS: 'Chi tiết lô',
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const mergeRibbonColor = (col1, col2) => {
  if (col2 === "" || col1 === col2) {
    return col1;
  }
  if (col1 < col2) {
    return `${col1}-${col2}`;
  }
  return `${col2}-${col1}`;
};

export default function Step2({ insertData }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        insertData.map((tabItem, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                {tabItem.name}
              </Typography>
              <Typography gutterBottom>{`${LL.WORK_DATE}: ${tabItem.workDate}`}</Typography>
              <Typography gutterBottom>{`${LL.MAN_HOUR}: ${tabItem.manHour}`}</Typography>
              <Typography gutterBottom>{`${LL.NUM_OF_MAN}: ${tabItem.numOfMan}`}</Typography>
              {
                tabItem.id === 0 ? (
                  <Typography gutterBottom>{`${LL.RIBBON_COLOR}: ${mergeRibbonColor(tabItem.ribbonColor, tabItem.ribbonColorSub)}`}</Typography>
                ) : tabItem.id === 1 ? (
                  <Typography gutterBottom>{`${LL.ACTIVITY_DETAILS}: ${tabItem.otherName}`}</Typography>
                ) : null
              }
              {
                tabItem.id === 0 || tabItem.id === 5 ? (
                  <>
                    {
                      tabItem.materials.map((material, subIndex) => (
                        <Typography gutterBottom key={subIndex}>{`${material.material}: ${material.value} ${material.metric}`}</Typography>
                      ))
                    }
                  </>
                ) : null
              }
            </Grid>
            <Grid item container direction="column" xs={12} sm={6}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                {LL.LOT_DETAILS}
              </Typography>
              <Grid container>
                {
                  tabItem.lot.map((lotItem, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <Grid item xs={6}>
                        <Typography gutterBottom>{`${lotItem.name} ${LL.ACTUAL_HA}`}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography gutterBottom>{lotItem.actualHa}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography gutterBottom>{`${lotItem.name} ${LL.ACTUAL_BUNCHES}`}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <Typography gutterBottom>{lotItem.actualBunches}</Typography>
                      </Grid>
                    </React.Fragment>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>    
        ))
      }
    </React.Fragment>
  );
}