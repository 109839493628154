import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DashboardContainer from '../containers/Dashboard'

const MENU = {
  USER_MANAGEMENT: { LABEL: 'USER_MANAGEMENT', URL: '/dashboard/user-management' },
  DATA_MANAGEMENT1: { LABEL: 'DATA_MANAGEMENT1', URL: '/dashboard/data-management1' },
  DATA_MANAGEMENT2: { LABEL: 'DATA_MANAGEMENT2', URL: '/dashboard/data-management2' },
  DATA_VIEWER: { LABEL: 'DATA_VIEWER', URL: '/dashboard/data-viewer' },
  DATA_CONTROL: { LABEL: 'DATA_CONTROL', URL: '/dashboard/data-control' },
  DAILY_SUBMISSION: { LABEL: 'DAILY_SUBMISSION', URL: '/dashboard/daily-submission' },
  SUBMISSION_HISTORY: { LABEL: 'SUBMISSION_HISTORY', URL: '/dashboard/submission-history' },
  PASSWORD_RESET: { LABEL: 'PASSWORD_RESET', URL: '/dashboard/password-reset' },
};

export default function DashboardPage({ subComp }) {
  const history = useHistory();
  const location = useLocation();
  const selectedMenuList = Object.values(MENU).find(menu => menu.URL === location.pathname);

  return (
    <DashboardContainer
      subComp={subComp}
      selectedMenu={selectedMenuList?.LABEL}
      goToUserManagement={() => history.push(MENU.USER_MANAGEMENT.URL)}
      goToDataManagement1={() => history.push(MENU.DATA_MANAGEMENT1.URL)}
      goToDataManagement2={() => history.push(MENU.DATA_MANAGEMENT2.URL)}
      goToDataViewer={() => history.push(MENU.DATA_VIEWER.URL)}
      goToDataControl={() => history.push(MENU.DATA_CONTROL.URL)}
      goToDailySubmission={() => history.push(MENU.DAILY_SUBMISSION.URL)}
      goToSubmissionHistory={() => history.push(MENU.SUBMISSION_HISTORY.URL)}
      goToPasswordReset={() => history.push(MENU.PASSWORD_RESET.URL)}
    />
  );
}
