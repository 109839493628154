import React from 'react';
import { useHistory } from 'react-router-dom';
import DailySubmissionContainer from '../containers/DailySubmission'

export default function DailySubmissionPage() {
  const history = useHistory();

  return (
    <DailySubmissionContainer
      goToDashboardHome={() => history.replace("/dashboard")}
    />
  );
}
