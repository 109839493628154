import * as harvestAPI from '../api/harvest';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest } from 'redux-saga/effects';

/* 액션 타입 */

// 수확물 저장
const CREATE_HARVEST = 'CREATE_HARVEST';
const CREATE_HARVEST_SUCCESS = 'CREATE_HARVEST_SUCCESS';
const CREATE_HARVEST_ERROR = 'CREATE_HARVEST_ERROR';

// 내 수확물 조회
const GET_MY_HARVEST_LIST = 'GET_MY_HARVEST_LIST';
const GET_MY_HARVEST_LIST_SUCCESS = 'GET_MY_HARVEST_LIST_SUCCESS';
const GET_MY_HARVEST_LIST_ERROR = 'GET_MY_HARVEST_LIST_ERROR';

// 수확물 조회
const GET_HARVEST_LIST = 'GET_HARVEST_LIST';
const GET_HARVEST_LIST_SUCCESS = 'GET_HARVEST_LIST_SUCCESS';
const GET_HARVEST_LIST_ERROR = 'GET_HARVEST_LIST_ERROR';

// 수확물 재료 조회
const GET_HARVEST_MATERIAL_LIST = 'GET_HARVEST_MATERIAL_LIST';
const GET_HARVEST_MATERIAL_LIST_SUCCESS = 'GET_HARVEST_MATERIAL_LIST_SUCCESS';
const GET_HARVEST_MATERIAL_LIST_ERROR = 'GET_HARVEST_MATERIAL_LIST_ERROR';

// 수확물 삭제
const DELETE_HARVEST = 'DELETE_HARVEST';
const DELETE_HARVEST_SUCCESS = 'DELETE_HARVEST_SUCCESS';
const DELETE_HARVEST_ERROR = 'DELETE_HARVEST_ERROR';

export const createHarvest = data => ({ type: CREATE_HARVEST, payload: data });
export const getMyHarvestList = () => ({ type: GET_MY_HARVEST_LIST });
export const getHarvestList = data => ({ type: GET_HARVEST_LIST, payload: data });
export const getHarvestMaterialList = data => ({ type: GET_HARVEST_MATERIAL_LIST, payload: data });
export const deleteHarvest = id => ({ type: DELETE_HARVEST, payload: id });

// 사가들을 합치기
export function* harvestSaga() {
    yield takeLatest(CREATE_HARVEST, createPromiseSaga(CREATE_HARVEST, harvestAPI.createHarvest));
    yield takeLatest(GET_MY_HARVEST_LIST, createPromiseSaga(GET_MY_HARVEST_LIST, harvestAPI.getMyHarvestList));
    yield takeLatest(GET_HARVEST_LIST, createPromiseSaga(GET_HARVEST_LIST, harvestAPI.getHarvestList));
    yield takeLatest(GET_HARVEST_MATERIAL_LIST, createPromiseSaga(GET_HARVEST_MATERIAL_LIST, harvestAPI.getHarvestMaterialList));
    yield takeLatest(DELETE_HARVEST, createPromiseSaga(DELETE_HARVEST, harvestAPI.deleteHarvest));
}

const initialState = {
    createHarvest: reducerUtils.initial(null),
    getMyHarvestList: reducerUtils.initial(null),
    getHarvestList: reducerUtils.initial(null),
    getHarvestMaterialList: reducerUtils.initial(null),
    deleteHarvest: reducerUtils.initial(null),

};

export default function harvest(state = initialState, action) {
    switch (action.type) {
        case CREATE_HARVEST:
        case CREATE_HARVEST_SUCCESS:
        case CREATE_HARVEST_ERROR:
            return handleAsyncActions(CREATE_HARVEST, 'createHarvest', false)(state, action);
        case GET_MY_HARVEST_LIST:
        case GET_MY_HARVEST_LIST_SUCCESS:
        case GET_MY_HARVEST_LIST_ERROR:
            return handleAsyncActions(GET_MY_HARVEST_LIST, 'getMyHarvestList', false)(state, action);
        case GET_HARVEST_LIST:
        case GET_HARVEST_LIST_SUCCESS:
        case GET_HARVEST_LIST_ERROR:
            return handleAsyncActions(GET_HARVEST_LIST, 'getHarvestList', false)(state, action);
        case GET_HARVEST_MATERIAL_LIST:
        case GET_HARVEST_MATERIAL_LIST_SUCCESS:
        case GET_HARVEST_MATERIAL_LIST_ERROR:
            return handleAsyncActions(GET_HARVEST_MATERIAL_LIST, 'getHarvestMaterialList', false)(state, action);
        case DELETE_HARVEST:
        case DELETE_HARVEST_SUCCESS:
        case DELETE_HARVEST_ERROR:
            return handleAsyncActions(DELETE_HARVEST, 'deleteHarvest', false)(state, action);
        default:
            return state;
    }
}
