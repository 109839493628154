import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const harvestColumns = [
  { field: 'submitDate', headerName: 'Ngày nộp dữ liệu', type: 'dateTime', width: 200 },
  { field: 'workDate', headerName: 'Ngày thực hiện', type: 'date', width: 150 },
  { field: 'farmerName', headerName: 'Tên', type: 'string', width: 150 },
  { field: 'farmerCountry', headerName: 'Quốc gia', type: 'string', width: 150 },
  { field: 'farmerCompany', headerName: 'Công ty', type: 'string', width: 150 },
  { field: 'farm', headerName: 'Nông trường', type: 'string', width: 150 },
  { field: 'tabName', headerName: 'Hạng mục công việc', type: 'string', width: 200 },
  { field: 'manHour', headerName: 'Man Hour', type: 'number', width: 150 },
  { field: 'numOfMan', headerName: 'Số luong công nhân', type: 'number', width: 150 },
  { field: 'lot', headerName: 'Mã lô', type: 'string', width: 150 },
  { field: 'actualHa', headerName: 'Diện tích thực hien', type: 'number', width: 150 },
  { field: 'actualBunches', headerName: 'Số lượng buông thục hien', type: 'number', width: 150 },
  { field: 'etc', headerName: 'etc', type: 'string', width: 150 },
];

const harvestMaterialColumns = [
  { field: 'submitDate', headerName: 'Ngày nộp dữ liệu', type: 'dateTime', width: 200 },
  { field: 'workDate', headerName: 'Ngày thực hiện', type: 'date', width: 150 },
  { field: 'farmerName', headerName: 'Tên', type: 'string', width: 150 },
  { field: 'farmerCountry', headerName: 'Quốc gia', type: 'string', width: 150 },
  { field: 'farmerCompany', headerName: 'Công ty', type: 'string', width: 150 },
  { field: 'farm', headerName: 'Nông trường', type: 'string', width: 150 },
  { field: 'tabName', headerName: 'Hạng mục công việc', type: 'string', width: 200 },
  { field: 'material', headerName: 'Material', type: 'string', width: 200 },  // TODO: translation
  { field: 'value', headerName: 'Value', type: 'string', width: 200 },
  { field: 'metric', headerName: 'Metric', type: 'string', width: 200 },
];

const packingSKUColumns = [
  { field: 'submitDate', headerName: 'Ngày nộp dữ liệu', type: 'dateTime', width: 200 },
  { field: 'workDate', headerName: 'Ngày thực hiện', type: 'date', width: 150 },
  { field: 'packerName', headerName: 'packerName', type: 'string', width: 150 },
  { field: 'packerCountry', headerName: 'packerCountry', type: 'string', width: 150 },
  { field: 'packerCompany', headerName: 'packerCompany', type: 'string', width: 150 },
  { field: 'farm', headerName: 'Nông trường', type: 'string', width: 150 },
  { field: 'SKU', headerName: 'SKU', type: 'number', width: 150 },
  { field: 'boxType', headerName: 'boxType', type: 'number', width: 150 },
  { field: 'numOfBox', headerName: 'numOfBox', type: 'string', width: 150 },
];

const packingRibbonColumns = [
  { field: 'submitDate', headerName: 'Ngày nộp dữ liệu', type: 'dateTime', width: 200 },
  { field: 'workDate', headerName: 'Ngày thực hiện', type: 'date', width: 150 },
  { field: 'packerName', headerName: 'packerName', type: 'string', width: 150 },
  { field: 'packerCountry', headerName: 'packerCountry', type: 'string', width: 150 },
  { field: 'packerCompany', headerName: 'packerCompany', type: 'string', width: 150 },
  { field: 'farm', headerName: 'Nông trường', type: 'string', width: 150 },
  { field: 'lot', headerName: 'Mã lô', type: 'number', width: 150 },
  { field: 'ribbonColor', headerName: 'Màu dây', type: 'number', width: 150 },
  { field: 'numOfBunch', headerName: 'numOfBunch', type: 'string', width: 150 },
];

const packingActivityColumns = [
  { field: 'submitDate', headerName: 'Ngày nộp dữ liệu', type: 'dateTime', width: 200 },
  { field: 'workDate', headerName: 'Ngày thực hiện', type: 'date', width: 150 },
  { field: 'packerName', headerName: 'packerName', type: 'string', width: 150 },
  { field: 'packerCountry', headerName: 'packerCountry', type: 'string', width: 150 },
  { field: 'packerCompany', headerName: 'packerCompany', type: 'string', width: 150 },
  { field: 'farm', headerName: 'Nông trường', type: 'string', width: 150 },
  { field: 'activity', headerName: 'Activity', type: 'number', width: 150 },  // TODO: translation
  { field: 'value', headerName: 'Value', type: 'number', width: 150 },
  { field: 'metric', headerName: 'Metric', type: 'string', width: 150 },
];

export default function ExportSelectorGrid({ menuList, getHarvestList, getHarvestMaterialList, getPackingList, dataLoading, harvestData, harvestMaterialData, packingData }) {
  const classes = useStyles();

  function CustomToolbar() {
    const csvOptions = {fileName: `${(new Date()).toISOString()}-${menuList[selectedMenuIndex]}`};
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={csvOptions} />
      </GridToolbarContainer>
    );
  }

  const yearMonthDay = new Date().toISOString().split('T')[0].split('-');
  const thisYear = yearMonthDay[0];
  const thisMonth = yearMonthDay[1];
  const thisDay = yearMonthDay[2];
  const yearList = [...Array(parseInt(thisYear)-2021+1).keys()].map(i => String(i+2021));
  const monthList = [...Array(12).keys()].map(i => String(i+1).padStart(2, '0'));
  const dayList = [...Array(31).keys()].map(i => String(i+1).padStart(2, '0'));

  const [year, setYear] = React.useState(thisYear);
  const [month, setMonth] = React.useState(thisMonth);
  const [day, setDay] = React.useState(thisDay);
  const [tempMenuIndex, setTempMenuIndex] = React.useState(0);
  const [selectedMenuIndex, setSelectedMenuIndex] = React.useState(-1);

  const loadData = (index, date) => {
    if (menuList[index] === "C - Lot") {
      getHarvestList(date);
    } else if (menuList[index] === "C - Material") {
      getHarvestMaterialList(date);
    } else if (menuList[index] === "D - SKU") {
      getPackingList({date: date, packingType: "SKU"});
    } else if (menuList[index] === "D - Ribbon") {
      getPackingList({date: date, packingType: "RIBBON"});
    } else if (menuList[index] === "D - Activity") {
      getPackingList({date: date, packingType: "ACTIVITY"});
    }
  }

  const searchBtnClick = () => {
    loadData(tempMenuIndex, `${year}-${month}-${day}`);
    setSelectedMenuIndex(tempMenuIndex);
  };

  const convertData = menu => {
    if (menu === "C - Lot") {
      return harvestData;
    } else if (menu === "C - Material") {
      return harvestMaterialData;
    } else if (menu === "D - SKU") {
      return packingData.map((item) => (
        {
          ...item,
          SKU: item.name,
          boxType: item.typ,
          numOfBox: item.num,
        }
      ));
    } else if (menu === "D - Ribbon") {
      return packingData.map((item) => (
        {
          ...item,
          lot: item.name,
          ribbonColor: item.typ,
          numOfBunch: item.num,
        }
      ));
    } else if (menu === "D - Activity") {
      return packingData.map((item) => (
        {
          ...item,
          activity: item.name,
          value: item.num,
          metric: item.typ,
        }
      ));
    }
    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              Data Viewer
            </Typography>
            <br/><br/>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Viewer Type</InputLabel>
                  <Select
                    value={tempMenuIndex}
                    onChange={e => setTempMenuIndex(e.target.value)}
                  >
                    {
                      menuList.map((menu, index) => (
                        <MenuItem key={index} value={index}>{menu}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    {
                      yearList.map((yyyy, index) => (
                        <MenuItem key={index} value={yyyy}>{yyyy}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    {
                      monthList.map((mm, index) => (
                        <MenuItem key={index} value={mm}>{mm}</MenuItem>
                      ))
                    }
                  </Select>
                  
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Day</InputLabel>
                  <Select
                    value={day}
                    onChange={e => setDay(e.target.value)}
                  >
                    {
                      dayList.map((dd, index) => (
                        <MenuItem key={index} value={dd}>{dd}</MenuItem>
                      ))
                    }
                  </Select>
                  
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} style={{textAlign: 'center', marginTop: '10px'}}>
                <Button onClick={() => searchBtnClick()} color="primary" variant="contained">
                  Search
                </Button>
              </Grid>
            </Grid>
            <br/><br/><br/>
            {
              dataLoading ? (
                <Loading />
              ) : (harvestData === null && harvestMaterialData === null && packingData === null) ? null : (
                <div style={{ height: 1000, width: '100%' }}>
                  <DataGrid
                    columns={
                      menuList[selectedMenuIndex] === "C - Lot" ? harvestColumns : 
                      menuList[selectedMenuIndex] === "C - Material" ? harvestMaterialColumns : 
                      menuList[selectedMenuIndex] === "D - SKU" ? packingSKUColumns : 
                      menuList[selectedMenuIndex] === "D - Ribbon" ? packingRibbonColumns :
                      menuList[selectedMenuIndex] === "D - Activity" ? packingActivityColumns : null
                    }
                    rows={convertData(menuList[selectedMenuIndex])}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              )
            }
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}