import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function SignUpForm({ initialData, signUp, handleClose }) {
  const [formData, setFormData] = React.useState(initialData);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  };

  const handleSignUp = () => {
    signUp(formData);
    handleClose();
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Create New User</DialogTitle>
      <DialogContent>
      <DialogContentText>
        Fill in User Account Information.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        name="id"
        label="Email Address"
        type="email"
        fullWidth
        value={formData.id}
        onChange={handleChange}
      />
      <TextField
        margin="dense"
        name="name"
        label="Name"
        type="text"
        fullWidth
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        margin="dense"
        name="pw"
        label="Password"
        type="password"
        fullWidth
        value={formData.pw}
        onChange={handleChange}
      />
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSignUp} color="primary">
        Create
      </Button>
      </DialogActions>
    </>
  );
}