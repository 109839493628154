import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Step1({ workDate, skuData, ribbonData, activityData }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            SKU
          </Typography>
          <Typography gutterBottom>{`work date: ${workDate}`}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            details
          </Typography>
          <Grid container>
            {
              skuData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>SKU</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Box Type</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.typ}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Number of Box</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.num}</Typography>
                  </Grid>
                  <br/><br/><br/>
                </React.Fragment>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
      <br/><br/><br/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Ribbon Packing
          </Typography>
          <Typography gutterBottom>{`work date: ${workDate}`}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            details
          </Typography>
          <Grid container>
            {
              ribbonData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>LOT</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Ribbon Color</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.typ}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Number of Bunch</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.num}</Typography>
                  </Grid>
                  <br/><br/><br/>
                </React.Fragment>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
      <br/><br/><br/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Activity
          </Typography>
          <Typography gutterBottom>{`work date: ${workDate}`}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            details
          </Typography>
          <Grid container>
            {
              activityData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Activity</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Value</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.num}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Metric</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{item.typ}</Typography>
                  </Grid>
                  <br/><br/><br/>
                </React.Fragment>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}