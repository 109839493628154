import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DailySubmissionControlForFarmerComponent from '../components/DailySubmissionControlForFarmer';
import DailySubmissionControlForPackerComponent from '../components/DailySubmissionControlForPacker';
import Loading from '../components/Loading';
import { getTabList, getRibbonColorList, getSkuTypeList, getBoxTypeList, getMaterialList, getActivityList } from '../modules/tab';
import { createHarvest, createPacking, getMyPackingColorList } from '../modules/control';
import { getUserDetail } from '../modules/user';

export default function DailySubmissionControlContainer({ userId, goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getTabListState = useSelector(state => state.tab.getTabList);
  const getRibbonColorListState = useSelector(state => state.tab.getRibbonColorList);
  const getMaterialListState = useSelector(state => state.tab.getMaterialList);
  const getActivityListState = useSelector(state => state.tab.getActivityList);
  const getSkuTypeListState = useSelector(state => state.tab.getSkuTypeList);
  const getBoxTypeListState = useSelector(state => state.tab.getBoxTypeList);
  const getUserDetailState = useSelector(state => state.user.getUserDetail);
  const createHarvestState = useSelector(state => state.control.createHarvest);
  const createPackingState = useSelector(state => state.control.createPacking);
  const getMyPackingColorListState = useSelector(state => state.control.getMyPackingColorList);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "B1") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    dispatch(getUserDetail(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (getUserDetailState && getUserDetailState.data) {
      const userGrade = getUserDetailState.data.grade;
      if (userGrade === "C") {
        dispatch(getTabList());
        dispatch(getRibbonColorList());
        dispatch(getMaterialList());
      } else if (userGrade === "D") {
        dispatch(getActivityList());
        dispatch(getMyPackingColorList(userId));
        dispatch(getSkuTypeList());
        dispatch(getBoxTypeList());
      }
    }
  }, [dispatch, getUserDetailState, userId]);

  if (getUserDetailState && getUserDetailState.data) {
    const userGrade = getUserDetailState.data.grade;
    if (userGrade === "C" && getTabListState.data && getRibbonColorListState.data && getMaterialListState.data) {
      return (
        <DailySubmissionControlForFarmerComponent
          goToDashboardHome={goToDashboardHome}
          userId={userId}
          userDetail={getUserDetailState.data.detail}
          tabList={getTabListState.data}
          ribbonColorList={getRibbonColorListState.data}
          materialList={getMaterialListState.data}
          createHarvest={data => dispatch(createHarvest(data))}
          isSuccess={!createHarvestState.loading && createHarvestState.error === null}
        />
      );
    } else if (userGrade === "D" && getActivityListState.data && getMyPackingColorListState.data && getSkuTypeListState.data && getBoxTypeListState.data) {
      return (
        <DailySubmissionControlForPackerComponent
          goToDashboardHome={goToDashboardHome}
          userId={userId}
          userDetail={getUserDetailState.data.detail}
          activityList={getActivityListState.data}
          ribbonColorList={getMyPackingColorListState.data}
          skuTypeList={getSkuTypeListState.data}
          boxTypeList={getBoxTypeListState.data}
          createPacking={data => dispatch(createPacking(data))}
          isSuccess={!createPackingState.loading && createPackingState.error === null}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else {
    return (
      <Loading />
    );
  }
}
