import axios from './common';

export const getUserList = async () => {
  const response = await axios.get('/api/auth/users');
  return response.data;
};

export const getUserDetail = async id => {
  const response = await axios.get('/api/auth/user-detail?id=' + id);
  return response.data;
};

export const checkIdUnique = async id => {
  const response = await axios.get('/api/auth/email-uniqueness?id=' + id);
  return response.data;
};

export const signUp = async data => {
  const response = await axios.post('/api/auth/signup', data);
  return response.data;
};

export const activateUser = async data => {
  const response = await axios.put('/api/auth/user-grade', data);
  return response.data;
};

export const updateUserDetail = async data => {
  const response = await axios.put('/api/auth/user-detail', data);
  return response.data;
};

export const resetPassword = async data => {
  const response = await axios.put('/api/auth/password-reset', data);
  return response.data;
};

export const deleteUser = async id => {
  const response = await axios.delete('/api/auth/users?id=' + id);
  return response.data;
};
