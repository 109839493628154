import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PasswordResetForm({ initialData, resetPassword, handleClose }) {
  const [formData, setFormData] = React.useState({
    id: initialData.id,
    pw: '',
    pwRe: '',
  });
  
  const [checkPassword, setCheckPassword] = React.useState(false);

  const handleChange = e => {
    const inputValue = e.target.value;
    const inputField = e.target.name;
    const oppositeField = inputField === 'pw' ? 'pwRe' : 'pw';
    
    setCheckPassword(inputValue !== '' && inputValue === formData[oppositeField]);
    setFormData({
      ...formData,
      [inputField]: inputValue
    })
  };

  const handleResetPassword = () => {
    if (formData.pw !== '' && checkPassword) {
      resetPassword(formData);
      handleClose();
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Reset Password.
        </DialogContentText>
        <TextField
          disabled
          margin="dense"
          name="id"
          label="Email Address"
          type="email"
          fullWidth
          value={formData.id}
        />
        <TextField
          margin="dense"
          name="pw"
          label="Password"
          type="password"
          fullWidth
          value={formData.pw}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="pwRe"
          label="Password Repeat"
          type="password"
          fullWidth
          value={formData.pwRe}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button disabled={!checkPassword} onClick={handleResetPassword} color="primary">
          Reset
        </Button>
      </DialogActions>
    </>
  );
}