import * as authAPI from '../api/auth';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest } from 'redux-saga/effects';
import { setAuthInfo, getAuthInfo, removeAuthInfo } from "../lib/authUtils";

/* 액션 타입 */

// 로그인
const SIGN_IN = 'SIGN_IN'; // 로그인
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'; // 요청 성공
const SIGN_IN_ERROR = 'SIGN_IN_ERROR'; // 요청 실패

// 로그아웃
const SIGN_OUT = 'SIGN_OUT';

export const signIn = credentials => ({ type: SIGN_IN, payload: credentials });
export const signOut = () => ({ type: SIGN_OUT });

const onSignInSuccess = ({ type, payload }) => {
    const { accessToken, userId, userRole } = payload;
    setAuthInfo(accessToken, userId, userRole);
    return { type, payload };
}

const signInSaga = createPromiseSaga(SIGN_IN, authAPI.signIn, onSignInSuccess);
function signOutSaga() {
    removeAuthInfo();
    window.location.reload();
}

// 사가들을 합치기
export function* authSaga() {
    yield takeLatest(SIGN_IN, signInSaga);
    yield takeLatest(SIGN_OUT, signOutSaga);
}

const initialState = {
    authInfo: reducerUtils.initial(getAuthInfo())
};

const signOutState = {
    authInfo: reducerUtils.initial({
        accessToken: null,
        userId: null,
        userRole: null,
    })
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case SIGN_IN:
        case SIGN_IN_SUCCESS:
        case SIGN_IN_ERROR:
            return handleAsyncActions(SIGN_IN, 'authInfo', true)(state, action);
        case SIGN_OUT:
            return signOutState;
        default:
            return state;
    }
}