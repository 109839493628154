import * as packingAPI from '../api/packing';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest } from 'redux-saga/effects';

/* 액션 타입 */

// 수확물 저장
const CREATE_PACKING = 'CREATE_PACKING';
const CREATE_PACKING_SUCCESS = 'CREATE_PACKING_SUCCESS';
const CREATE_PACKING_ERROR = 'CREATE_PACKING_ERROR';

// 내 수확물 조회
const GET_MY_PACKING_LIST = 'GET_MY_PACKING_LIST';
const GET_MY_PACKING_LIST_SUCCESS = 'GET_MY_PACKING_LIST_SUCCESS';
const GET_MY_PACKING_LIST_ERROR = 'GET_MY_PACKING_LIST_ERROR';

// 수확물 조회
const GET_PACKING_LIST = 'GET_PACKING_LIST';
const GET_PACKING_LIST_SUCCESS = 'GET_PACKING_LIST_SUCCESS';
const GET_PACKING_LIST_ERROR = 'GET_PACKING_LIST_ERROR';

// 수확물 삭제
const DELETE_PACKING = 'DELETE_PACKING';
const DELETE_PACKING_SUCCESS = 'DELETE_PACKING_SUCCESS';
const DELETE_PACKING_ERROR = 'DELETE_PACKING_ERROR';

// 리본 칼라 조회
const GET_MY_PACKING_COLOR_LIST = 'GET_MY_PACKING_COLOR_LIST';
const GET_MY_PACKING_COLOR_LIST_SUCCESS = 'GET_MY_PACKING_COLOR_LIST_SUCCESS';
const GET_MY_PACKING_COLOR_LIST_ERROR = 'GET_MY_PACKING_COLOR_LIST_ERROR';

export const createPacking = data => ({ type: CREATE_PACKING, payload: data });
export const getMyPackingList = () => ({ type: GET_MY_PACKING_LIST });
export const getPackingList = data => ({ type: GET_PACKING_LIST, payload: data });
export const deletePacking = id => ({ type: DELETE_PACKING, payload: id });
export const getMyPackingColorList = () => ({ type: GET_MY_PACKING_COLOR_LIST });

// 사가들을 합치기
export function* packingSaga() {
    yield takeLatest(CREATE_PACKING, createPromiseSaga(CREATE_PACKING, packingAPI.createPacking));
    yield takeLatest(GET_MY_PACKING_LIST, createPromiseSaga(GET_MY_PACKING_LIST, packingAPI.getMyPackingList));
    yield takeLatest(GET_PACKING_LIST, createPromiseSaga(GET_PACKING_LIST, packingAPI.getPackingList));
    yield takeLatest(DELETE_PACKING, createPromiseSaga(DELETE_PACKING, packingAPI.deletePacking));
    yield takeLatest(GET_MY_PACKING_COLOR_LIST, createPromiseSaga(GET_MY_PACKING_COLOR_LIST, packingAPI.getMyPackingColorList));
}

const initialState = {
    createPacking: reducerUtils.initial(null),
    getMyPackingList: reducerUtils.initial(null),
    getPackingList: reducerUtils.initial(null),
    deletePacking: reducerUtils.initial(null),
    getMyPackingColorList: reducerUtils.initial(null),
};

export default function packing(state = initialState, action) {
    switch (action.type) {
        case CREATE_PACKING:
        case CREATE_PACKING_SUCCESS:
        case CREATE_PACKING_ERROR:
            return handleAsyncActions(CREATE_PACKING, 'createPacking', false)(state, action);
        case GET_MY_PACKING_LIST:
        case GET_MY_PACKING_LIST_SUCCESS:
        case GET_MY_PACKING_LIST_ERROR:
            return handleAsyncActions(GET_MY_PACKING_LIST, 'getMyPackingList', false)(state, action);
        case GET_PACKING_LIST:
        case GET_PACKING_LIST_SUCCESS:
        case GET_PACKING_LIST_ERROR:
            return handleAsyncActions(GET_PACKING_LIST, 'getPackingList', false)(state, action);
        case DELETE_PACKING:
        case DELETE_PACKING_SUCCESS:
        case DELETE_PACKING_ERROR:
            return handleAsyncActions(DELETE_PACKING, 'deletePacking', false)(state, action);
        case GET_MY_PACKING_COLOR_LIST:
        case GET_MY_PACKING_COLOR_LIST_SUCCESS:
        case GET_MY_PACKING_COLOR_LIST_ERROR:
            return handleAsyncActions(GET_MY_PACKING_COLOR_LIST, 'getMyPackingColorList', false)(state, action);
        default:
            return state;
    }
}
