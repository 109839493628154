import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DailySubmissionControlContainer from '../containers/DailySubmissionControl'

const useQuery = () => new URLSearchParams(useLocation().search);

export default function DailySubmissionControlPage() {
  const history = useHistory();
  const query = useQuery();

  return (
    <DailySubmissionControlContainer
      userId={query.get("userId")}
      goToDashboardHome={() => history.replace("/dashboard")}
    />
  );
}
