import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const LL = {
  SELECT_DATE: 'Chọn ngày',
  SUBMISSION_DATE: 'Ngày nộp dữ liệu',
  WORK_DATE: 'Ngày thực hiện',
  WEEK: 'Tuần',
  PERIOD: 'Giai đoạn',
  LOCATION: 'Vị trí',
  FILL_IN: 'Fill-In',
  SELECT: 'Lựa chọn',
  LOT: 'Mã lô',
  RIBBON_COLOR: 'Màu dây',
  ACTIVITY: 'Hạng mục việc',
  VALUE: 'Số lượng',
  METRIC: 'Đơn vị tính',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getPeriod = weekNum => {
  return parseInt((weekNum - 1) / 4) + 1;
};

export default function Step0({ userDetail, workDateObj, workDate, activityData, skuData, ribbonData, setWorkDate, setActivityData, setSkuData, setRibbonData, activityList, ribbonColorList, skuTypeList, boxTypeList }) {
  const classes = useStyles();

  const defaultWeekNum = moment(workDate).add(-1, 'days').week();
  const [weekNum, setWeekNum] = React.useState(defaultWeekNum);
  const [period, setPeriod] = React.useState(getPeriod(defaultWeekNum));

  const handleWorkDate = e => {
    const selectedDate = e.target.value;
    const pWeekNum = moment(selectedDate).add(-1, 'days').week();
    setWeekNum(pWeekNum);
    setPeriod(getPeriod(pWeekNum));
    setWorkDate(selectedDate);
  };

  const handleAddSKU = () => {
    setSkuData([
      ...skuData,
      {
        name: '',
        typ: '',
        num: '',
      },
    ]);
  };

  const handleAddRibbon = () => {
    setRibbonData([
      ...ribbonData,
      {
        name: '',
        typ: '',
        num: '',
      },
    ]);
  };

  const handleAddActivity = () => {
    setActivityData([
      ...activityData,
      {
        name: '',
        typ: '',
        num: '',
      },
    ]);
  };

  const handleSKUChange = e => {
    const labelArr = e.target.name.split('.');
    const index = parseInt(labelArr[0]);
    const field = labelArr[1];

    setSkuData([
      ...skuData.slice(0, index),
      {
        ...skuData[index],
        [field]: e.target.value,
      },
      ...skuData.slice(index+1),
    ]);
  };

  const handleRibbonChange = e => {
    const labelArr = e.target.name.split('.');
    const index = parseInt(labelArr[0]);
    const field = labelArr[1];

    setRibbonData([
      ...ribbonData.slice(0, index),
      {
        ...ribbonData[index],
        [field]: e.target.value,
      },
      ...ribbonData.slice(index+1),
    ]);
  };

  const handleActivityChange = e => {
    const labelArr = e.target.name.split('.');
    const index = parseInt(labelArr[0]);
    const field = labelArr[1];
    const value = e.target.value;

    setActivityData([
      ...activityData.slice(0, index),
      {
        ...activityData[index],
        [field]: value,
        typ: field === 'name' ? activityList.find(a => a.name === value).metric : activityData[index].typ,
      },
      ...activityData.slice(index+1),
    ]);
  };

  const handleRemoveSKU = index => {
    setSkuData([
      ...skuData.slice(0, index),
      ...skuData.slice(index+1),
    ]);
  };

  const handleRemoveRibbon = index => {
    setRibbonData([
      ...ribbonData.slice(0, index),
      ...ribbonData.slice(index+1),
    ]);
  };

  const handleRemoveActivity = index => {
    setActivityData([
      ...activityData.slice(0, index),
      ...activityData.slice(index+1),
    ]);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {LL.SELECT_DATE}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="submitDate"
            name="submitDate"
            label={LL.SUBMISSION_DATE}
            fullWidth
            value={workDateObj.maxDate}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="workDate"
            type="date"
            name="workDate"
            label={LL.WORK_DATE}
            inputProps={{min: workDateObj.minDate, max: workDateObj.maxDate}}
            value={workDate}
            onChange={handleWorkDate}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="week"
            name="week"
            label={LL.WEEK}
            fullWidth
            value={weekNum}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="period"
            name="period"
            label={LL.PERIOD}
            fullWidth
            value={period}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="location"
            name="location"
            label={LL.LOCATION}
            fullWidth
            value={userDetail.farm}
            disabled={true}
          />
        </Grid>
      </Grid>
      <br/><br/>
      <Typography variant="h6" gutterBottom>
        {`${LL.FILL_IN} SKU`}
        <IconButton color="primary" onClick={handleAddSKU}>
          <AddBoxIcon />
        </IconButton>
      </Typography>
      {
        skuData.map((item, index) => (
          <Grid container spacing={3} key={index}>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel>SKU</InputLabel>
                <Select
                  name={`${index}.name`}
                  fullWidth
                  value={item.name}
                  onChange={handleSKUChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    skuTypeList.map(skuType => (
                      <MenuItem key={skuType.id} value={skuType.sku}>{skuType.sku}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel>Box Type</InputLabel>
                <Select
                  name={`${index}.typ`}
                  fullWidth
                  value={item.typ}
                  onChange={handleSKUChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    boxTypeList.map(boxType => (
                      <MenuItem key={boxType.id} value={boxType.box}>{boxType.box}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name={`${index}.num`}
                label="Number of Box"
                fullWidth
                value={item.num}
                onChange={handleSKUChange}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton color="primary" onClick={() => handleRemoveSKU(index)}>
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))
      }
      <br/><br/>
      <Typography variant="h6" gutterBottom>
        {`${LL.FILL_IN} Ribbon Packing`}
        <IconButton color="primary" onClick={handleAddRibbon}>
          <AddBoxIcon />
        </IconButton>
      </Typography>
      {
        ribbonData.map((item, index) => (
          <Grid container spacing={3} key={index}>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel>{LL.LOT}</InputLabel>
                <Select
                  name={`${index}.name`}
                  fullWidth
                  value={item.name}
                  onChange={handleRibbonChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    userDetail.lot.map(lot => (
                      <MenuItem key={lot} value={lot}>{lot}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel>{LL.RIBBON_COLOR}</InputLabel>
                <Select
                  name={`${index}.typ`}
                  fullWidth
                  value={item.typ}
                  onChange={handleRibbonChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    ribbonColorList.map(ribbonColor => (
                      <MenuItem key={ribbonColor} value={ribbonColor}>{ribbonColor}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name={`${index}.num`}
                label="Number of Bunch"
                fullWidth
                value={item.num}
                onChange={handleRibbonChange}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton color="primary" onClick={() => handleRemoveRibbon(index)}>
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))
      }
      <br/><br/>
      <Typography variant="h6" gutterBottom>
        {`${LL.FILL_IN} Activity`}
        <IconButton color="primary" onClick={handleAddActivity}>
          <AddBoxIcon />
        </IconButton>
      </Typography>
      {
        activityData.map((item, index) => (
          <Grid container spacing={3} key={index}>
            <Grid item xs={12} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel>{LL.ACTIVITY}</InputLabel>
                <Select
                  name={`${index}.name`}
                  fullWidth
                  value={item.name}
                  onChange={handleActivityChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    activityList.map(activity => (
                      <MenuItem key={activity.id} value={activity.name}>{activity.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name={`${index}.num`}
                label={LL.VALUE}
                fullWidth
                value={item.num}
                onChange={handleActivityChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name={`${index}.typ`}
                label={LL.METRIC}
                fullWidth
                disabled
                value={item.typ}
                onChange={handleActivityChange}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton color="primary" onClick={() => handleRemoveActivity(index)}>
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))
      }
    </React.Fragment>
  );
}