export function setAuthInfo(accessToken, userId, userRole) {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("userId", userId);
  localStorage.setItem("userRole", userRole);
}

export function getAuthInfo() {
  return {
      accessToken: localStorage.getItem("accessToken"),
      userId: localStorage.getItem("userId"),
      userRole: localStorage.getItem("userRole"),
  };
}

export function removeAuthInfo() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userId");
  localStorage.removeItem("userRole");
}
