import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubmissionHistoryForFarmerComponent from '../components/SubmissionHistoryForFarmer';
import SubmissionHistoryForPackerComponent from '../components/SubmissionHistoryForPacker';
import Loading from '../components/Loading';
import { getMyHarvestList, deleteHarvest } from '../modules/harvest';
import { getMyPackingList, deletePacking } from '../modules/packing';

export default function SubmissionHistoryContainer({ goToDashboardHome, goToSubmissionPage }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getMyHarvestListState = useSelector(state => state.harvest.getMyHarvestList);
  const deleteHarvestState = useSelector(state => state.harvest.deleteHarvest);
  const getMyPackingListState = useSelector(state => state.packing.getMyPackingList);
  const deletePackingState = useSelector(state => state.packing.deletePacking);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "C" && userRole !== "D") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    if (userRole === "C") {
      dispatch(getMyHarvestList());
    } else if (userRole === "D") {
      dispatch(getMyPackingList());
    }
  }, [dispatch, userRole]);

  useEffect(() => {
    if (userRole === "C" && deleteHarvestState.data) {
      dispatch(getMyHarvestList());
    }
  }, [dispatch, userRole, deleteHarvestState.data]);

  useEffect(() => {
    if (userRole === "D" && deletePackingState.data) {
      dispatch(getMyPackingList());
    }
  }, [dispatch, userRole, deletePackingState.data]);

  if (userRole === "C") {
    if (getMyHarvestListState.data) {
      return (
        <SubmissionHistoryForFarmerComponent
          goToSubmissionPage={goToSubmissionPage}
          harvestList={getMyHarvestListState.data}
          deleteHarvest={id => dispatch(deleteHarvest(id))}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else if (userRole === "D") {
    if (getMyPackingListState.data) {
      return (
        <SubmissionHistoryForPackerComponent
          goToSubmissionPage={goToSubmissionPage}
          packingList={getMyPackingListState.data}
          deletePacking={id => dispatch(deletePacking(id))}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else {
    return null;
  }
  
}
