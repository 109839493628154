import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PasswordResetComponent from '../components/PasswordReset';
import { resetPassword } from '../modules/user';

export default function PasswordResetContainer() {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const resetPasswordState = useSelector(state => state.user.resetPassword);
  const dispatch = useDispatch();

  return (
    <PasswordResetComponent
      userId={authInfoState.data.userId}
      resetPassword={data => dispatch(resetPassword(data))}
      isSuccess={!resetPasswordState.loading && resetPasswordState.error === null && resetPasswordState.data !== null}
      isError={!resetPasswordState.loading && resetPasswordState.error !== null}
    />
  );
}
