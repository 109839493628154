import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataControlComponent from '../components/DataControl';
import { getUserList } from '../modules/control';

export default function DataControlContainer({ goToDashboardHome, goToDailySubmission, goToSubmissionHistory }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getUserListState = useSelector(state => state.control.getUserList);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "B1") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  return (
    <DataControlComponent
      userList={getUserListState.data}
      getUserList={(userGrade) => dispatch(getUserList(userGrade))}
      goToDailySubmission={goToDailySubmission}
      goToSubmissionHistory={goToSubmissionHistory}
    />
  );
}
