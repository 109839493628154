import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChipsArray from './LotChipsArray';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ModifyForm({ initialData, updateUserDetail, handleClose }) {
  const classes = useStyles();
  const [formData, setFormData] = React.useState(initialData);
  
  const handleDetailChange = e => {
    setFormData({
      ...formData,
      detail: {
        ...formData.detail,
        [e.target.name]: e.target.value,
      }
    })
  };

  const handleLotChange = lot => {
    setFormData({
      ...formData,
      detail: {
        ...formData.detail,
        lot: lot,
      }
    })
  };

  const handleLotKeyDown = e => {
    const inputValue = e.target.value;
    const isNotSame = formData.detail.lot.filter(lot => lot === inputValue).length === 0;
    if (e.keyCode === 13 && inputValue !== '' && isNotSame) {
      handleLotChange([...formData.detail.lot, inputValue]);
      e.target.value = '';
    }
  };

  const handleUpdateUserDetail = () => {
    updateUserDetail(formData);
    handleClose();
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Update User Detail</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update User Detail Information.
        </DialogContentText>
        <TextField
          disabled
          margin="dense"
          name="id"
          label="Email Address"
          type="email"
          fullWidth
          value={formData.id}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Grade</InputLabel>
          <Select
            disabled
            labelId="grade-select-label"
            name="grade"
            value={formData.grade}
          >
            <MenuItem value={'N'}>N</MenuItem>
            <MenuItem value={'A'}>A</MenuItem>
            <MenuItem value={'B1'}>B1</MenuItem>
            <MenuItem value={'B2'}>B2</MenuItem>
            <MenuItem value={'B3'}>B3</MenuItem>
            <MenuItem value={'C'}>C</MenuItem>
            <MenuItem value={'D'}>D</MenuItem>
          </Select>
        </FormControl>
        {
          formData.grade === 'B1' || formData.grade === 'B2' || formData.grade === 'B3' ? (
            <>
              <TextField
                margin="dense"
                name="country"
                label="Country"
                type="text"
                fullWidth
                value={formData.detail.country}
                onChange={handleDetailChange}
              />
              <TextField
                margin="dense"
                name="company"
                label="Company"
                type="text"
                fullWidth
                value={formData.detail.company}
                onChange={handleDetailChange}
              />
            </>
          ) : formData.grade === 'C' || formData.grade === 'D' ? (
            <>
              <TextField
                margin="dense"
                name="country"
                label="Country"
                type="text"
                fullWidth
                value={formData.detail.country}
                onChange={handleDetailChange}
              />
              <TextField
                margin="dense"
                name="company"
                label="Company"
                type="text"
                fullWidth
                value={formData.detail.company}
                onChange={handleDetailChange}
              />
              <TextField
                margin="dense"
                name="farm"
                label="Farm"
                type="text"
                fullWidth
                value={formData.detail.farm}
                onChange={handleDetailChange}
              />
              <TextField
                margin="dense"
                name="lot"
                label="Lot"
                type="text"
                helperText="To add Lot, press Enter"
                onKeyDown={handleLotKeyDown}
              />
              <br/><br/>
              <ChipsArray
                lots={formData.detail.lot}
                setLotData={handleLotChange}
              />
            </>
          ) : (
            <></>
          )
        }
        </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdateUserDetail} color="primary">
          Update
        </Button>
      </DialogActions>
    </>
  );
}
