import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SubmissionRow from './SubmissionRowForPacker';

const LL = {
  DELETE: 'Xóa',
  SUBMISSION_HISTORY: 'Dữ liệu đã nộp',
  DAILY_SUBMISSION: 'Báo cáo ngày',
  SUBMISSION_DATE: 'Ngày nộp dữ liệu',
  WORK_DATE: 'Ngày thực hiện',
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function ConfirmDialog({ open, setOpen, handleDelete }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{LL.DELETE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Would you like to delete submission?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          {LL.DELETE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function SubmissionHistoryTable({ goToSubmissionPage, packingList, deletePacking }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(-1);

  const onDeleteClick = id => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleDelete = () => {
    if (deleteId > 0) {
      deletePacking(deleteId);
      setOpen(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              {LL.SUBMISSION_HISTORY}
            </Typography>
            <div style={{textAlign: 'right'}}>
              <Button variant="contained" color="primary" onClick={() => goToSubmissionPage()}>
                {LL.DAILY_SUBMISSION}
              </Button>
            </div>
            <ConfirmDialog
              open={open}
              setOpen={setOpen}
              handleDelete={handleDelete}
            />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">{LL.SUBMISSION_DATE}</TableCell>
                    <TableCell align="center">{LL.WORK_DATE}</TableCell>
                    <TableCell align="center" colSpan={4}>Details</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">SKU / LOT / Activity</TableCell>
                    <TableCell align="center">BOX TYPE/ RIBBON COLOR / Metric</TableCell>
                    <TableCell align="center">Number</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    packingList.map(row => (
                      <SubmissionRow
                        key={row.id}
                        data={row}
                        onDeleteClick={onDeleteClick}
                      />
                    ))
                  }
                </TableBody>
              </Table>
              </TableContainer>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
