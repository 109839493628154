import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SnackBar from './SnackBar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function PasswordResetComponent({ userId, resetPassword, isSuccess, isError }) {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({
    id: userId,
    opw: '',
    pw: '',
    pwRe: '',
  });

  const [checkPassword, setCheckPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('');
  const [msg, setMsg] = React.useState('');


  useEffect(() => {
    if (isSuccess) {
      setSeverity('success');
      setMsg('password reset success!');
      setOpen(true);
      setFormData({
        id: userId,
        opw: '',
        pw: '',
        pwRe: '',
      });
    }
    if (isError) {
      setSeverity('error');
      setMsg('wrong password!');
      setOpen(true);
    }
  }, [userId, isSuccess, isError]);

  const handleOldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = e => {
    const inputValue = e.target.value;
    const inputField = e.target.name;
    const oppositeField = inputField === 'pw' ? 'pwRe' : 'pw';
    
    setCheckPassword(inputValue !== '' && inputValue === formData[oppositeField]);
    setFormData({
      ...formData,
      [inputField]: inputValue,
    })
  };

  const handleResetPassword = () => {
    if (formData.opw !== '' && formData.pw !== '' && checkPassword) {
      resetPassword(formData);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <SnackBar
              open={open}
              setOpen={setOpen}
              severity={severity}
              msg={msg}
            />
            <Typography component="h1" variant="h4" align="center">
              Password Reset
            </Typography>
            <div style={{textAlign: 'center'}}>
              <TextField
                margin="dense"
                name="opw"
                label="Old Password"
                type="password"
                value={formData.opw}
                onChange={handleOldChange}
              />
              <br/>
              <TextField
                margin="dense"
                name="pw"
                label="New Password"
                type="password"
                value={formData.pw}
                onChange={handleChange}
              />
              <br/>
              <TextField
                margin="dense"
                name="pwRe"
                label="New Password Repeat"
                type="password"
                value={formData.pwRe}
                onChange={handleChange}
              />
              <br/><br/>
              <Button disabled={!checkPassword} onClick={handleResetPassword} color="primary" variant="contained">
                Reset
              </Button>
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
