import axios from './common';

export const getUserList = async userGrade => {
  const response = await axios.get('/api/control/users?userGrade=' + userGrade);
  return response.data;
};

export const createHarvest = async data => {
  const response = await axios.post('/api/control/harvests', data);
  return response.data;
};

export const createPacking = async data => {
  const response = await axios.post('/api/control/packings', data);
  return response.data;
};

export const getMyPackingColorList = async userId => {
  const response = await axios.get('/api/control/my-packing-colors?userId=' + userId);
  return response.data;
};

export const getMyHarvestList = async userId => {
  const response = await axios.get('/api/control/my-harvests?userId=' + userId);
  return response.data;
};

export const getMyPackingList = async userId => {
  const response = await axios.get('/api/control/my-packings?userId=' + userId);
  return response.data;
};

export const deleteHarvest = async id => {
  const response = await axios.delete('/api/control/harvests/' + id);
  return response.data;
};

export const deletePacking = async id => {
  const response = await axios.delete('/api/control/packings/' + id);
  return response.data;
};
