import React from 'react';
import { useHistory } from 'react-router-dom';
import DataManagementContainer2 from '../containers/DataManagement2'

export default function DataManagementPage1() {
  const history = useHistory();

  return (
    <DataManagementContainer2
    goToDashboardHome={() => history.replace("/dashboard")}
    />
  );
}
