import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export default function FormDialog({ open, handleClose, formElement }) {
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      onClose={handleClose}
    >
      {formElement}
    </Dialog>
  );
}