import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const LL = {
  SELECT: 'Lựa chọn',
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DataControlComponent({ userList, getUserList, goToDailySubmission, goToSubmissionHistory }) {
  const classes = useStyles();

  const [userGrade, setUserGrade] = React.useState('');
  const [userId, setUserId] = React.useState('');

  const handleUserGradeChange = e => {
    const grade = e.target.value;
    setUserGrade(grade);
    setUserId('');
    if (grade === 'C' || grade === 'D') {
      getUserList(grade);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              Data Control
            </Typography>
            <div style={{textAlign: 'center'}}>
              <FormControl className={classes.formControl}>
                <InputLabel>User Grade</InputLabel>
                <Select
                  name="userGrade"
                  value={userGrade}
                  onChange={handleUserGradeChange}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                  <MenuItem value="D">D</MenuItem>
                </Select>
              </FormControl>
              <br/>
              <FormControl className={classes.formControl}>
                <InputLabel>User Id</InputLabel>
                <Select
                  name="userId"
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                >
                  <MenuItem value={''}>{LL.SELECT}</MenuItem>
                  {
                    userGrade === '' || userList === null ? null : userList.map(user => (
                      <MenuItem key={user.email} value={user.email}>{user.email}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <br/><br/>
              <Button disabled={userId===''} onClick={() => goToDailySubmission(userId)} color="primary" variant="contained">
                Daily Submission
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button disabled={userId===''} onClick={() => goToSubmissionHistory(userId)} color="secondary" variant="contained">
                Submission History
              </Button>
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
