import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipsArray({ lots, setLotData }) {
  const classes = useStyles();

  const handleDelete = (lotToDelete) => () => {
    setLotData(lots.filter((lot) => lot !== lotToDelete));
  };

  return (
    <Paper component="ul" className={classes.root}>
      {
        lots.map((lot) => {
          return (
            <li key={lot}>
              <Chip
                label={lot}
                onDelete={handleDelete(lot)}
                className={classes.chip}
              />
            </li>
          );
        })
      }
    </Paper>
  );
}
