import React from 'react';
import { useHistory } from 'react-router-dom';
import DataViewerContainer from '../containers/DataViewer'

export default function DataViewerPage() {
  const history = useHistory();

  return (
    <DataViewerContainer
    goToDashboardHome={() => history.replace("/dashboard")}
    />
  );
}
