import * as controlAPI from '../api/control';
import {
    reducerUtils,
    handleAsyncActions,
    createPromiseSaga,
} from '../lib/asyncUtils';
import { takeLatest } from 'redux-saga/effects';

/* 액션 타입 */

// 유저목록 조회
const CONTROL_GET_USER_LIST = 'CONTROL_GET_USER_LIST';
const CONTROL_GET_USER_LIST_SUCCESS = 'CONTROL_GET_USER_LIST_SUCCESS';
const CONTROL_GET_USER_LIST_ERROR = 'CONTROL_GET_USER_LIST_ERROR';

// 수확물 저장
const CONTROL_CREATE_HARVEST = 'CONTROL_CREATE_HARVEST';
const CONTROL_CREATE_HARVEST_SUCCESS = 'CONTROL_CREATE_HARVEST_SUCCESS';
const CONTROL_CREATE_HARVEST_ERROR = 'CONTROL_CREATE_HARVEST_ERROR';

// 수확물 저장
const CONTROL_CREATE_PACKING = 'CONTROL_CREATE_PACKING';
const CONTROL_CREATE_PACKING_SUCCESS = 'CONTROL_CREATE_PACKING_SUCCESS';
const CONTROL_CREATE_PACKING_ERROR = 'CONTROL_CREATE_PACKING_ERROR';

// 리본 칼라 조회
const CONTROL_GET_MY_PACKING_COLOR_LIST = 'CONTROL_GET_MY_PACKING_COLOR_LIST';
const CONTROL_GET_MY_PACKING_COLOR_LIST_SUCCESS = 'CONTROL_GET_MY_PACKING_COLOR_LIST_SUCCESS';
const CONTROL_GET_MY_PACKING_COLOR_LIST_ERROR = 'CONTROL_GET_MY_PACKING_COLOR_LIST_ERROR';

// 내 수확물 조회
const CONTROL_GET_MY_HARVEST_LIST = 'CONTROL_GET_MY_HARVEST_LIST';
const CONTROL_GET_MY_HARVEST_LIST_SUCCESS = 'CONTROL_GET_MY_HARVEST_LIST_SUCCESS';
const CONTROL_GET_MY_HARVEST_LIST_ERROR = 'CONTROL_GET_MY_HARVEST_LIST_ERROR';

// 내 수확물 조회
const CONTROL_GET_MY_PACKING_LIST = 'CONTROL_GET_MY_PACKING_LIST';
const CONTROL_GET_MY_PACKING_LIST_SUCCESS = 'CONTROL_GET_MY_PACKING_LIST_SUCCESS';
const CONTROL_GET_MY_PACKING_LIST_ERROR = 'CONTROL_GET_MY_PACKING_LIST_ERROR';

// 수확물 삭제
const CONTROL_DELETE_HARVEST = 'CONTROL_DELETE_HARVEST';
const CONTROL_DELETE_HARVEST_SUCCESS = 'CONTROL_DELETE_HARVEST_SUCCESS';
const CONTROL_DELETE_HARVEST_ERROR = 'CONTROL_DELETE_HARVEST_ERROR';

// 수확물 삭제
const CONTROL_DELETE_PACKING = 'CONTROL_DELETE_PACKING';
const CONTROL_DELETE_PACKING_SUCCESS = 'CONTROL_DELETE_PACKING_SUCCESS';
const CONTROL_DELETE_PACKING_ERROR = 'CONTROL_DELETE_PACKING_ERROR';

export const getUserList = userGrade => ({ type: CONTROL_GET_USER_LIST, payload: userGrade });
export const createHarvest = data => ({ type: CONTROL_CREATE_HARVEST, payload: data });
export const createPacking = data => ({ type: CONTROL_CREATE_PACKING, payload: data });
export const getMyPackingColorList = userId => ({ type: CONTROL_GET_MY_PACKING_COLOR_LIST, payload: userId });
export const getMyHarvestList = userId => ({ type: CONTROL_GET_MY_HARVEST_LIST, payload: userId });
export const getMyPackingList = userId => ({ type: CONTROL_GET_MY_PACKING_LIST, payload: userId });
export const deleteHarvest = id => ({ type: CONTROL_DELETE_HARVEST, payload: id });
export const deletePacking = id => ({ type: CONTROL_DELETE_PACKING, payload: id });

// 사가들을 합치기
export function* controlSaga() {
    yield takeLatest(CONTROL_GET_USER_LIST, createPromiseSaga(CONTROL_GET_USER_LIST, controlAPI.getUserList));
    yield takeLatest(CONTROL_CREATE_HARVEST, createPromiseSaga(CONTROL_CREATE_HARVEST, controlAPI.createHarvest));
    yield takeLatest(CONTROL_CREATE_PACKING, createPromiseSaga(CONTROL_CREATE_PACKING, controlAPI.createPacking));
    yield takeLatest(CONTROL_GET_MY_PACKING_COLOR_LIST, createPromiseSaga(CONTROL_GET_MY_PACKING_COLOR_LIST, controlAPI.getMyPackingColorList));
    yield takeLatest(CONTROL_GET_MY_HARVEST_LIST, createPromiseSaga(CONTROL_GET_MY_HARVEST_LIST, controlAPI.getMyHarvestList));
    yield takeLatest(CONTROL_GET_MY_PACKING_LIST, createPromiseSaga(CONTROL_GET_MY_PACKING_LIST, controlAPI.getMyPackingList));
    yield takeLatest(CONTROL_DELETE_HARVEST, createPromiseSaga(CONTROL_DELETE_HARVEST, controlAPI.deleteHarvest));
    yield takeLatest(CONTROL_DELETE_PACKING, createPromiseSaga(CONTROL_DELETE_PACKING, controlAPI.deletePacking));
}

const initialState = {
    getUserList: reducerUtils.initial(null),
    createHarvest: reducerUtils.initial(null),
    createPacking: reducerUtils.initial(null),
    getMyPackingColorList: reducerUtils.initial(null),
    getMyHarvestList: reducerUtils.initial(null),
    getMyPackingList: reducerUtils.initial(null),
    deleteHarvest: reducerUtils.initial(null),
    deletePacking: reducerUtils.initial(null),
};

export default function control(state = initialState, action) {
    switch (action.type) {
        case CONTROL_GET_USER_LIST:
        case CONTROL_GET_USER_LIST_SUCCESS:
        case CONTROL_GET_USER_LIST_ERROR:
            return handleAsyncActions(CONTROL_GET_USER_LIST, 'getUserList', false)(state, action);
        case CONTROL_CREATE_HARVEST:
        case CONTROL_CREATE_HARVEST_SUCCESS:
        case CONTROL_CREATE_HARVEST_ERROR:
            return handleAsyncActions(CONTROL_CREATE_HARVEST, 'createHarvest', false)(state, action);
        case CONTROL_CREATE_PACKING:
        case CONTROL_CREATE_PACKING_SUCCESS:
        case CONTROL_CREATE_PACKING_ERROR:
            return handleAsyncActions(CONTROL_CREATE_PACKING, 'createPacking', false)(state, action);
        case CONTROL_GET_MY_PACKING_COLOR_LIST:
        case CONTROL_GET_MY_PACKING_COLOR_LIST_SUCCESS:
        case CONTROL_GET_MY_PACKING_COLOR_LIST_ERROR:
            return handleAsyncActions(CONTROL_GET_MY_PACKING_COLOR_LIST, 'getMyPackingColorList', false)(state, action);
        case CONTROL_GET_MY_HARVEST_LIST:
        case CONTROL_GET_MY_HARVEST_LIST_SUCCESS:
        case CONTROL_GET_MY_HARVEST_LIST_ERROR:
            return handleAsyncActions(CONTROL_GET_MY_HARVEST_LIST, 'getMyHarvestList', false)(state, action);
        case CONTROL_GET_MY_PACKING_LIST:
        case CONTROL_GET_MY_PACKING_LIST_SUCCESS:
        case CONTROL_GET_MY_PACKING_LIST_ERROR:
            return handleAsyncActions(CONTROL_GET_MY_PACKING_LIST, 'getMyPackingList', false)(state, action);
        case CONTROL_DELETE_HARVEST:
        case CONTROL_DELETE_HARVEST_SUCCESS:
        case CONTROL_DELETE_HARVEST_ERROR:
            return handleAsyncActions(CONTROL_DELETE_HARVEST, 'deleteHarvest', false)(state, action);
        case CONTROL_DELETE_PACKING:
        case CONTROL_DELETE_PACKING_SUCCESS:
        case CONTROL_DELETE_PACKING_ERROR:
            return handleAsyncActions(CONTROL_DELETE_PACKING, 'deletePacking', false)(state, action);
        default:
            return state;
    }
}
