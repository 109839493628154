import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubmissionHistoryControlForFarmerComponent from '../components/SubmissionHistoryControlForFarmer';
import SubmissionHistoryControlForPackerComponent from '../components/SubmissionHistoryControlForPacker';
import Loading from '../components/Loading';
import { getMyHarvestList, deleteHarvest, getMyPackingList, deletePacking } from '../modules/control';
import { getUserDetail } from '../modules/user';

export default function SubmissionHistoryControlContainer({ userId, goToDashboardHome, goToSubmissionPage }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getMyHarvestListState = useSelector(state => state.control.getMyHarvestList);
  const deleteHarvestState = useSelector(state => state.control.deleteHarvest);
  const getMyPackingListState = useSelector(state => state.control.getMyPackingList);
  const deletePackingState = useSelector(state => state.control.deletePacking);
  const getUserDetailState = useSelector(state => state.user.getUserDetail);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "B1") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    dispatch(getUserDetail(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (getUserDetailState && getUserDetailState.data) {
      const userGrade = getUserDetailState.data.grade;
      if (userGrade === "C") {
        dispatch(getMyHarvestList(userId));
      } else if (userGrade === "D") {
        dispatch(getMyPackingList(userId));
      }
    }
  }, [dispatch, getUserDetailState, userId]);

  useEffect(() => {
    if (getUserDetailState && getUserDetailState.data) {
      const userGrade = getUserDetailState.data.grade;
      if (userGrade === "C" && deleteHarvestState.data) {
        dispatch(getMyHarvestList(userId));
      }
    }
  }, [dispatch, getUserDetailState, userId, deleteHarvestState.data]);

  useEffect(() => {
    if (getUserDetailState && getUserDetailState.data) {
      const userGrade = getUserDetailState.data.grade;
      if (userGrade === "D" && deletePackingState.data) {
        dispatch(getMyPackingList(userId));
      }
    }
  }, [dispatch, getUserDetailState, userId, deletePackingState.data]);

  if (getUserDetailState && getUserDetailState.data) {
    const userGrade = getUserDetailState.data.grade;
    if (userGrade === "C" && getMyHarvestListState.data) {
      return (
        <SubmissionHistoryControlForFarmerComponent
          userId={userId}
          goToSubmissionPage={goToSubmissionPage}
          harvestList={getMyHarvestListState.data}
          deleteHarvest={id => dispatch(deleteHarvest(id))}
        />
      );
    } else if (userGrade === "D" && getMyPackingListState.data) {
      return (
        <SubmissionHistoryControlForPackerComponent
          userId={userId}
          goToSubmissionPage={goToSubmissionPage}
          packingList={getMyPackingListState.data}
          deletePacking={id => dispatch(deletePacking(id))}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else {
    return (
      <Loading />
    );
  }
}
