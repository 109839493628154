import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteForm({ initialData, deleteUser, handleClose }) {

  const handleDeleteUser = () => {
    deleteUser(initialData.id);
    handleClose();
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Delete User Account.
        </DialogContentText>
        <TextField
          disabled
          margin="dense"
          name="id"
          label="Email Address"
          type="email"
          fullWidth
          value={initialData.id}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteUser} color="primary">
          Delete
        </Button>
      </DialogActions>
    </>
  );
}