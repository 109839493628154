import axios from './common';

export const createHarvest = async data => {
  const response = await axios.post('/api/harvests', data);
  return response.data;
};

export const getHarvestList = async date => {
  const response = await axios.get('/api/harvests?date=' + date);
  return response.data;
};

export const getHarvestMaterialList = async date => {
  const response = await axios.get('/api/harvest-materials?date=' + date);
  return response.data;
};

export const getMyHarvestList = async () => {
  const response = await axios.get('/api/my-harvests');
  return response.data;
};

export const deleteHarvest = async id => {
  const response = await axios.delete('/api/harvests/' + id);
  return response.data;
};
