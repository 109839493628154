import React from 'react';
import { useHistory } from 'react-router-dom';
import SubmissionHistoryContainer from '../containers/SubmissionHistory'

export default function SubmissionHistoryPage() {
  const history = useHistory();

  return (
    <SubmissionHistoryContainer
      goToDashboardHome={() => history.replace("/dashboard")}
      goToSubmissionPage={() => history.push("/dashboard/daily-submission")}
    />
  );
}
