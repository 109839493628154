import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import UserRow from './UserRow';
import FormDialog from './UserDialog';
import SignUpForm from './UserSignUpForm';
import ActivateForm from './UserActivateForm';
import DeleteForm from './UserDeleteForm';
import ModifyForm from './UserModifyForm';
import PasswordResetForm from './UserPwResetForm';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function UserManagementTable({ userList, userDetail, getUserDetail, signUp,
                                              activateUser, updateUserDetail, resetPassword, deleteUser }) {
  const classes = useStyles();

  const [formOpen, setFormOpen] = React.useState(false);
  const [formElement, setFormElement] = React.useState(null);
  const [formType, setFormType] = React.useState({type: '', id: -1});

  const [modifyLoading, setModifyLoading] = React.useState(false);
  const [activatedLoading, setActivatedLoading] = React.useState(false);
  const [pwResetLoading, setPwResetLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  useEffect(() => {
    if (userDetail && modifyLoading) {
      setModifyLoading(false);
      setFormOpen(true);
    } else if (userDetail && activatedLoading) {
      setActivatedLoading(false);
      setFormOpen(true);
    } else if (userDetail && pwResetLoading) {
      setPwResetLoading(false);
      setFormOpen(true);
    } else if (userDetail && deleteLoading) {
      setDeleteLoading(false);
      setFormOpen(true);
    }
  }, [userDetail, modifyLoading, activatedLoading, pwResetLoading, deleteLoading]);

  const handleSignUpFormOpen = () => {
    setFormType({type: 'signUp', id: -1});
    setFormOpen(true);
  };

  const handleFormOpen = (typeName, setLoadingFn) => id => {
    setFormType({type: typeName, id: id});
    getUserDetail(id);
    setLoadingFn(true);
  };

  const handleFormEnter = () => {
    switch (formType.type) {
      case 'signUp':
        setFormElement(
          <SignUpForm
            initialData={{ 
              id: '',
              name: '',
              pw: '',
              pwCheck: ''
            }}
            signUp={signUp}
            handleClose={handleFormClose}
          />
        );
        break;
      case 'activate':
        setFormElement(
          <ActivateForm
            initialData={userDetail}
            activateUser={activateUser}
            handleClose={handleFormClose}
          />
        );
        break;
      case 'delete':
        setFormElement(
          <DeleteForm
            initialData={userDetail}
            deleteUser={deleteUser}
            handleClose={handleFormClose}
          />
        );
        break;
      case 'modify':
        setFormElement(
          <ModifyForm
            initialData={userDetail}
            updateUserDetail={updateUserDetail}
            handleClose={handleFormClose}
          />
        );
        break;
      case 'passwordReset':
        setFormElement(
          <PasswordResetForm
            initialData={userDetail}
            resetPassword={resetPassword}
            handleClose={handleFormClose}
          />
        );
        break;
      default:
        setFormElement(null);
    };
  };

  const handleFormClose = () => {
    setFormType({type: '', id: -1});
    setFormOpen(false);
  };

  const handleFormExited = () => {
    setFormElement(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              User Management
            </Typography>
            <FormDialog
              open={formOpen}
              onEnter={handleFormEnter}
              handleClose={handleFormClose}
              onExited={handleFormExited}
              formElement={formElement}
            />
            <div style={{textAlign: 'right'}}>
              <Button variant="contained" color="primary" onClick={handleSignUpFormOpen}>
                Create New User
              </Button>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>ID (E-mail)</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Grade</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row, index) => (
                    <UserRow
                      key={row.email}
                      row={row}
                      onActivateClick={handleFormOpen('activate', setActivatedLoading)}
                      onPasswordResetClick={handleFormOpen('passwordReset', setPwResetLoading)}
                      onModifyClick={handleFormOpen('modify', setModifyLoading)}
                      onDeleteClick={handleFormOpen('delete', setDeleteLoading)}
                    />
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
