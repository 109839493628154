import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DailySubmissionForFarmerComponent from '../components/DailySubmissionForFarmer';
import DailySubmissionForPackerComponent from '../components/DailySubmissionForPacker';
import Loading from '../components/Loading';
import { getTabList, getRibbonColorList, getSkuTypeList, getBoxTypeList, getMaterialList, getActivityList } from '../modules/tab';
import { createHarvest } from '../modules/harvest';
import { createPacking, getMyPackingColorList } from '../modules/packing';
import { getUserDetail } from '../modules/user';

export default function DailySubmissionContainer({ goToDashboardHome }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const getTabListState = useSelector(state => state.tab.getTabList);
  const getRibbonColorListState = useSelector(state => state.tab.getRibbonColorList);
  const getMaterialListState = useSelector(state => state.tab.getMaterialList);
  const getActivityListState = useSelector(state => state.tab.getActivityList);
  const getSkuTypeListState = useSelector(state => state.tab.getSkuTypeList);
  const getBoxTypeListState = useSelector(state => state.tab.getBoxTypeList);
  const getUserDetailState = useSelector(state => state.user.getUserDetail);
  const createHarvestState = useSelector(state => state.harvest.createHarvest);
  const createPackingState = useSelector(state => state.packing.createPacking);
  const getMyPackingColorListState = useSelector(state => state.packing.getMyPackingColorList);
  const dispatch = useDispatch();
  const userId = authInfoState.data.userId;
  const userRole = authInfoState.data.userRole;

  useEffect(() => {
    if (userRole !== "C" && userRole !== "D") goToDashboardHome();
  }, [dispatch, userRole, goToDashboardHome]);

  useEffect(() => {
    if (userRole === "C") {
      dispatch(getTabList());
      dispatch(getRibbonColorList());
      dispatch(getMaterialList());
    } else if (userRole === "D") {
      dispatch(getActivityList());
      dispatch(getMyPackingColorList());
      dispatch(getSkuTypeList());
      dispatch(getBoxTypeList());
    }
    
    dispatch(getUserDetail(userId));
  }, [dispatch, userRole, userId]);

  if (userRole === "C") {
    if (getUserDetailState.data && getTabListState.data && getRibbonColorListState.data && getMaterialListState.data) {
      return (
        <DailySubmissionForFarmerComponent
          goToDashboardHome={goToDashboardHome}
          userDetail={getUserDetailState.data.detail}
          tabList={getTabListState.data}
          ribbonColorList={getRibbonColorListState.data}
          materialList={getMaterialListState.data}
          createHarvest={data => dispatch(createHarvest(data))}
          isSuccess={!createHarvestState.loading && createHarvestState.error === null}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else if (userRole === "D") {
    if (getUserDetailState.data && getActivityListState.data && getMyPackingColorListState.data && getSkuTypeListState.data && getBoxTypeListState.data) {
      return (
        <DailySubmissionForPackerComponent
          goToDashboardHome={goToDashboardHome}
          userDetail={getUserDetailState.data.detail}
          activityList={getActivityListState.data}
          ribbonColorList={getMyPackingColorListState.data}
          skuTypeList={getSkuTypeListState.data}
          boxTypeList={getBoxTypeListState.data}
          createPacking={data => dispatch(createPacking(data))}
          isSuccess={!createPackingState.loading && createPackingState.error === null}
        />
      );
    } else {
      return (
        <Loading />
      );
    }
  } else {
    return null;
  }
  
}
