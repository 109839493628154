import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SignInContainer from '../containers/SignIn'

export default function SignInPage() {
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/dashboard" } };

  return (
    <SignInContainer
      goToPrevPage={() => history.replace(from.pathname)}
    />
  );
}
