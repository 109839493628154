import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardComponent from '../components/Dashboard';
import { signOut } from '../modules/auth';

export default function DashboardContainer({ subComp, selectedMenu, goToUserManagement, goToDataManagement1, goToDataManagement2, goToDataViewer, goToDataControl,
                                             goToDailySubmission, goToSubmissionHistory, goToPasswordReset }) {
  const authInfoState = useSelector(state => state.auth.authInfo);
  const dispatch = useDispatch();
  const userRole = authInfoState.data.userRole === "A" ? "ADMIN"
    : authInfoState.data.userRole === "B1" || authInfoState.data.userRole === "B2" || authInfoState.data.userRole === "B3" ? "OFFICER"
    : authInfoState.data.userRole === "C" ? "FARMER"
    : authInfoState.data.userRole === "D" ? "PACKER"
    : "N";

  return (
    <DashboardComponent
      subComp={subComp}
      signOut={() => dispatch(signOut())}
      userRole={userRole}
      selectedMenu={selectedMenu}
      goToUserManagement={goToUserManagement}
      goToDataManagement1={goToDataManagement1}
      goToDataManagement2={goToDataManagement2}
      goToDataViewer={goToDataViewer}
      goToDataControl={goToDataControl}
      goToDailySubmission={goToDailySubmission}
      goToSubmissionHistory={goToSubmissionHistory}
      goToPasswordReset={goToPasswordReset}
    />
  );
}
