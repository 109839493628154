import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Loading from '../components/Loading';
import FormDialog from '../components/DataDialog';
import DeleteForm from '../components/DataDeleteForm';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DataManagementTable({ activityList, materialList, createActivity, createMaterial, deleteActivity, deleteMaterial }) {
  const classes = useStyles();
  const [formOpen, setFormOpen] = React.useState(false);
  const [formElement, setFormElement] = React.useState(null);
  const [menu, setMenu] = React.useState('activity');
  const dataList = menu === 'activity' ? activityList : menu === 'material' ? materialList : null;
  const [inputData1, setInputData1] = React.useState('');
  const [inputData2, setInputData2] = React.useState('');

  const handleMenuChange = e => {
    const newMenu = e.target.value;
    setMenu(newMenu);
    setInputData1('');
    setInputData2('');
  };

  const handleAddClick = () => {
    switch (menu) {
      case 'activity':
        createActivity({'name': inputData1, 'metric': inputData2});
        break;
      case 'material':
        createMaterial({'material': inputData1, 'metric': inputData2});
        break;
      default:
        break;
    }
    setInputData1('');
    setInputData2('');
  };

  const handleDeleteClick = row => {
    const item = menu === 'activity' ? row.name : menu === 'material' ? row.material : null;
    const deleteData = menu === 'activity' ? deleteActivity : menu === 'material' ? deleteMaterial : null;
    setFormOpen(true);
    setFormElement(
      <DeleteForm
        id={row.id}
        item={item}
        deleteData={deleteData}
        handleClose={handleFormClose}
      />
    );
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center">
              Data Management 2
            </Typography>
            <FormDialog
              open={formOpen}
              handleClose={handleFormClose}
              formElement={formElement}
            />
            <FormControl className={classes.formControl}>
              <InputLabel>Data Type</InputLabel>
              <Select
                value={menu}
                style={{width: 200}}
                onChange={handleMenuChange}
              >
                <MenuItem value='activity'>Activity - D</MenuItem>
                <MenuItem value='material'>Material - C</MenuItem>
              </Select>
            </FormControl>
            <div style={{textAlign: 'right'}}>
              <TextField
                label="Add Label"
                value={inputData1}
                style={{width: '200px'}}
                onChange={e => setInputData1(e.target.value)}
              />
              &nbsp;
              <TextField
                label="Add Metric"
                value={inputData2}
                style={{width: '200px'}}
                onChange={e => setInputData2(e.target.value)}
              />
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                style={{verticalAlign: 'bottom'}}
                disabled={inputData1 === '' || inputData2 === ''}
                onClick={handleAddClick}
              >
                Add
              </Button>
            </div>
            {
              dataList === null ? <Loading /> : (
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">Item</TableCell>
                        <TableCell align="center">Metric</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {
                          dataList.map((row) => (
                            <TableRow className={classes.tableRow} key={row.id}>
                              <TableCell>
                              </TableCell>
                              <TableCell align="center">
                                {
                                  menu === 'activity' ? row.name :
                                  menu === 'material' ? row.material :
                                  null
                                }
                              </TableCell>
                              <TableCell align="center">
                                {row.metric}
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup size="small" aria-label="small outlined button group">
                                  <Button color="secondary" onClick={() => handleDeleteClick(row)}>delete</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                  </Table>
                </TableContainer>
              )
            }
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
